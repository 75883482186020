import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { modifyMaterialBasedOnConflicts } from './insertEstimatedOptionOverlay'
import { drawAndLabelPaths } from './markupDocument'
import { createModification } from '../../../api/projects-api'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { RegionTool, Select, Workspace } from '../../lib/toolBoxes/2D'
import { getCurrentDrawableLocationsFromRootState, getOpacities, selectCurrentOverlayChunkId } from '../../slices/2D'
import {
    resetMaterialModificationConflicts,
    selectMaterialModificationConflicts,
    setSavedMaterialModifications,
    updateConflictResolutionFlag,
} from '../../slices/masterSetPlan'
import { selectProjectId } from '../../slices/project'
import { IMUP2DDrawableLocation, REGION_ENUMS } from '../../types'

export const createModificationAction = createAction('createModification')

export function* handleModificationCreation() {
    const manager: PaperManager | null = yield call(managers.get2DManager)
    const projectId: number | null = yield select(selectProjectId)
    const overlaidChunkId: number | null = yield select(selectCurrentOverlayChunkId)
    const drawableLocations: IMUP2DDrawableLocation[] = yield select(getCurrentDrawableLocationsFromRootState)
    const { lineOpacity, areaOpacity }: { lineOpacity: number; areaOpacity: number } = yield select(getOpacities)

    if (!manager || !projectId || !overlaidChunkId) return

    const [selectTool, workspaceTool, regionTool]: [Select, Workspace, RegionTool] = yield call(manager.getTools, [
        Select.NAME,
        Workspace.NAME,
        RegionTool.NAME,
    ])

    yield call(selectTool.exitSelectMode)

    const { materialModificationConflictsToSave }: ReturnType<typeof selectMaterialModificationConflicts> =
        yield select(selectMaterialModificationConflicts)

    if (materialModificationConflictsToSave?.length) {
        const mods: IMaterialModificationConflict[] = yield call(
            createModification,
            materialModificationConflictsToSave,
            projectId
        )

        yield put(setSavedMaterialModifications(mods))
    }

    yield put(resetMaterialModificationConflicts())

    yield put(updateConflictResolutionFlag(true))

    const regionPaths = workspaceTool.getItemsWithCriteria(
        'data',
        (data) => data.shapeType === REGION_ENUMS.TYPE
    ) as paper.Path[]

    yield call(
        drawAndLabelPaths,
        overlaidChunkId,
        { lineOpacity, areaOpacity },
        drawableLocations,
        regionPaths,
        workspaceTool,
        regionTool
    )

    const visibilityTest = (item: paper.Item): boolean => !isNonAreaJoistLine(item)

    const allPaperDrawablesOnPage: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        function (data: any) {
            return data?.drawable_id || data?.aiSuggestion?.id
        }
    )

    yield all(
        allPaperDrawablesOnPage.map((item) =>
            call(
                (item: paper.Item, visTest: (item: paper.Item) => boolean) => {
                    item.visible = visTest(item)
                },
                item,
                visibilityTest
            )
        )
    )

    yield call(modifyMaterialBasedOnConflicts, workspaceTool, overlaidChunkId)
}

export function* watchForModificationCreation() {
    yield takeLatest(createModificationAction.type, handleModificationCreation)
}
