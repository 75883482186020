import { all, call, put, select } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'
import { getMeasurementLabelForToolObject } from './handleCreateMeasurements'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { fetchOpeningGroupsByProjectId, updateDocumentMapping } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { IMasterSetPlanOption, MASTER_SET_PLAN_ENUMS } from '../../../models/masterSetPlan'
import { IToolObject } from '../../../models/tool'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { MasterSetPlanRegion, Workspace } from '../../lib/toolBoxes/2D'
import { preparedDrawablesSuccess } from '../../slices/2D'
import {
    resetDocumentChunkCalibration,
    selectDocumentChunks,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../slices/documents'
import { selectMasterSetPlanOptions, updateMasterSetPlanOptions } from '../../slices/masterSetPlan'
import { selectProjectId } from '../../slices/project'
import { selectMeasurementToolObjects, updateToolObjectByToolId } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'

export function* updateLabelForToolObjectInStore(
    toolObject: IToolObject,
    chunk: DocumentChunk,
    mapping: DocumentMapping
) {
    const newLabel: string = yield call(getMeasurementLabelForToolObject, toolObject, chunk, mapping)

    yield put(
        updateToolObjectByToolId({
            ...toolObject,
            settings: {
                ...toolObject.settings,
                label: newLabel,
            },
        })
    )
}

export function* handleUpdateMeasurementToolObjectLabels(chunk: DocumentChunk, mapping: DocumentMapping) {
    if (mapping.document_chunk_id !== chunk.id) return

    const toolObjects: IToolObject[] = yield select(selectMeasurementToolObjects)

    yield all(
        toolObjects.map((object) => {
            if (object.document_chunk_id === chunk.id) {
                return call(updateLabelForToolObjectInStore, object, chunk, mapping)
            }
        })
    )
}

export function* handleUpdateSingleDocumentMapping2D({ payload }: ReturnType<typeof updateSingleDocumentMapping>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, masterSetPlanRegionTool]: [Workspace, MasterSetPlanRegion] = yield call(
            manager.getTools,
            [Workspace.NAME, MasterSetPlanRegion.NAME]
        )
        const projectId: number = yield select(selectProjectId)
        const documentChunks: DocumentChunk[] | null = yield select(selectDocumentChunks)
        const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
        const masterSetOptions: IMasterSetPlanOption[] = yield select(selectMasterSetPlanOptions)
        const mappingChunk = documentChunks?.find((chunk) => chunk.id === payload.document_chunk_id)

        yield call(updateDocumentMapping, projectId, {
            ...payload,
            updated_at: undefined,
            created_at: undefined,
            deleted_at: undefined,
        } as DocumentMapping)

        // Update the mapping in the store
        yield put(updateDocumentMappings([payload]))

        yield put(
            updateMasterSetPlanOptions(
                masterSetOptions.map((option) => {
                    if (option.id === payload.id) {
                        return {
                            ...option,
                            name: payload.page_name ?? '',
                            scale: payload.scale_factor,
                            type: payload.type,
                        }
                    }

                    return option
                })
            )
        )

        const foundDocumentMapping = yield documentMappings.find((documentMapping) => documentMapping.id === payload.id)

        if (mappingChunk && foundDocumentMapping && foundDocumentMapping.scale_factor !== payload.scale_factor) {
            yield put(resetDocumentChunkCalibration({ documentChunkId: mappingChunk.id }))
            yield call(handleUpdateMeasurementToolObjectLabels, mappingChunk, payload)
        }

        // Fetch opening groups from api
        const openingGroups: OpeningGroupAPI[] = yield call(fetchOpeningGroupsByProjectId, projectId)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: IMUP2DDrawableLocation[] = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: number[] = [...new Set(drawableLocations.map((location) => location.document_chunk_id))]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))

        const optionItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data.masterSetPlanId === payload.id
        )

        if (optionItems.length > 1) throw 'More than one option path found something is wrong'

        const labelItem: paper.PointText | null = yield call(
            workspaceTool.getItemWithPaperId<paper.PointText>,
            optionItems[0].data.labelId
        )

        const labelBackground: paper.Item | null = yield call(
            workspaceTool.getItemWithPaperId,
            optionItems[0].data.labelBackgroundId
        )

        if (!labelItem || !labelBackground) return

        yield call(
            masterSetPlanRegionTool.updatePathColorAndText.bind(masterSetPlanRegionTool),
            optionItems[0],
            labelBackground,
            labelItem,
            payload.is_option
                ? MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_OPTION
                : MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME
        )
    } catch (error) {
        yield call(console.error, error)
    }
}
