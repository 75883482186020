import isNull from 'lodash/isNull'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { handleToggleDrawableGroups2D } from './handleToggleDrawableGroups'
import { drawAndLabelPaths, markupDocument, selectMarkupState } from './markupDocument'
import { ActiveFloor } from '../../../models/activeFloor'
import { DocumentMapping } from '../../../models/documentMapping'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { PROJECT_PAGE_TAB_VALUES } from '../../../shared/constants/project-settings-names'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay, Pan, RegionTool, Workspace } from '../../lib/toolBoxes/2D/tools'
import { selectOpacityDict, selectOverlayColor, setEstimatedOptionOverlayChunkId } from '../../slices/2D'
import { NormalizedDocumentChunk, selectDocumentMappings, selectNormalizedDocumentChunks } from '../../slices/documents'
import { GeometryGroup } from '../../slices/geometry'
import { selectSavedMaterialModifications, updateConflictResolutionFlag } from '../../slices/masterSetPlan'
import { getActiveTab } from '../../slices/navigation'
import { ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation, REGION_ENUMS } from '../../types'

interface DocumentMarkupStoreState {
    drawableLocations: IMUP2DDrawableLocation[]
    openingGroups: GeometryGroup[]
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
    activeFloor: ActiveFloor | null
}

export function* modifyMaterialBasedOnConflicts(workspaceTool: Workspace, overlayId?: number) {
    const savedMaterialModifications: IMaterialModificationConflict[] = yield select(selectSavedMaterialModifications)
    const matModIds = savedMaterialModifications.reduce((acc, matMod) => {
        if (overlayId && matMod.option_ids.includes(overlayId)) {
            acc.push(matMod.material_location_id)
        }

        return acc
    }, [] as number[])

    // get all workspace items that match the material modifications opening location ids
    const matModItems: paper.Path[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
        matModIds.includes(data.opening_location_id)
    )

    // sort material modifications into groups to be removed and to be adjusted
    const { matModsToHide, matModsToAdjust } = matModItems.reduce(
        (acc, item) => {
            const foundMatMod = savedMaterialModifications.find(
                (mm) => mm.material_location_id === item.data.opening_location_id
            )

            if (foundMatMod) {
                workspaceTool.compareCoordinates(item, foundMatMod.coordinates, foundMatMod.cutouts || [])
                    ? acc.matModsToHide.push(item)
                    : acc.matModsToAdjust.push(item)
            }

            return acc
        },
        { matModsToHide: [] as paper.Path[], matModsToAdjust: [] as paper.Path[] }
    )

    // remove items that were deleted in conflict resolution
    yield all(matModsToHide.map((item) => item.remove()))

    // adjust items that were adjusted in conflict resolution
    yield all(
        matModsToAdjust.map((item) => {
            const foundMatMod = savedMaterialModifications.find(
                (mm) => mm.material_location_id === item.data.opening_location_id
            )

            if (foundMatMod) {
                workspaceTool.updateItemCoordinates(item, foundMatMod.coordinates, foundMatMod.cutouts || [])
            }
        })
    )
}

export function* handleInsertEstimatedOptionOverlay(action: ReturnType<typeof setEstimatedOptionOverlayChunkId>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (isNull(manager)) return

        // get markup state from redux store
        const { openingGroups, areaOpacity, lineOpacity, drawableLocations, activeFloor }: DocumentMarkupStoreState =
            yield select(selectMarkupState)

        // get tools from manager
        const [workspaceTool, overlayTool, panTool, regionTool]: [Workspace, Overlay, Pan, RegionTool] = yield call(
            manager.getTools,
            [Workspace.NAME, Overlay.NAME, Pan.NAME, RegionTool.NAME]
        )

        const activeTab: PROJECT_PAGE_TAB_VALUES = yield select(getActiveTab)

        if (overlayTool.isLocked || action.payload === null) {
            yield call(overlayTool.resetOverlayRasters)
            yield call(overlayTool.resetSelectedImagesAndCrossHairs)
            yield call(overlayTool.lockOverlay, false)
            if (activeTab === PROJECT_PAGE_TAB_VALUES.DIGITIZER) {
                yield call(markupDocument)
            }

            yield put(updateConflictResolutionFlag(false))
            if (action.payload === null) return
        }

        const normalizedChunks: NormalizedDocumentChunk[] = yield select(selectNormalizedDocumentChunks)
        const chunkOpacityDict: Record<number, number> = yield select(selectOpacityDict)
        const possibleChunk: NormalizedDocumentChunk | undefined = normalizedChunks.find(
            (docChunk) => docChunk.id === action.payload
        )

        if (!possibleChunk) return

        const { src, id } = possibleChunk
        const chunkOpacity: number = isFinite(chunkOpacityDict[id])
            ? chunkOpacityDict[id] / 100
            : Overlay.DEFAULT_OPACITY

        const overlayColor = yield select(selectOverlayColor)

        yield call(overlayTool.insertOverlay, src, id, chunkOpacity, overlayColor)

        const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
        const mapping = documentMappings.find((map) => map.document_chunk_id === action.payload)
        const overlay_center = mapping?.overlay_center

        yield put(updateConflictResolutionFlag(mapping?.is_estimation_complete || false))

        if (!mapping || !overlay_center || !overlay_center.length) {
            yield call(overlayTool.resetOverlayRasters)
            yield call(overlayTool.resetSelectedImagesAndCrossHairs)
            throw new Error(`Either there is no document mapping associated with ${id}, or overlay_center was not set`)
        }

        yield call(overlayTool.setOverlayPositionCoordinates, id, overlay_center)

        const regionPaths = workspaceTool.getItemsWithCriteria(
            'data',
            (data) => data.shapeType === REGION_ENUMS.TYPE
        ) as paper.Path[]

        yield call(
            drawAndLabelPaths,
            activeFloor?.document_chunk.id ?? null,
            { lineOpacity, areaOpacity },
            drawableLocations,
            regionPaths,
            workspaceTool,
            regionTool
        )

        // determine ids of hidden drawables groups
        const hiddenDrawableGroupIds: Set<number> = openingGroups.reduce((groupIds, { id, isActive }) => {
            if (Object.values(isActive).includes(false)) {
                groupIds.add(id)
            }

            return groupIds
        }, new Set<number>())

        // build visibility predicate with hidden drawable group ids
        const visibilityTest = (item: paper.Item): boolean =>
            !(hiddenDrawableGroupIds.has(item.data.opening_group_id) || isNonAreaJoistLine(item))

        // hide drawable groups per visibility test predicate
        yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)

        yield call(modifyMaterialBasedOnConflicts, workspaceTool, action.payload)

        // Hide all drawables that are inactive by default
        yield fork(handleToggleDrawableGroups2D)

        yield call(overlayTool.lockOverlay, true)

        yield call(panTool.activate)
    } catch (error) {
        yield call(console.error, error as any)
    }
}

export function* watchForSelectingNewEstimatedOptionOverlay() {
    yield takeLatest(setEstimatedOptionOverlayChunkId.type, handleInsertEstimatedOptionOverlay)
}
