export const TOASTER_TIMER = 2000

export const otherValue = 'other'

export const tabNameToFetchKeyMap = {
    lumber_settings: 'lumber',
    exterior_settings: 'exterior_roofing',
    waste_factors: 'framing_waste_factor',
    project_materials: 'framing_project_material',
    eaves_and_gables_settings: 'eaves_and_gables',
}

// used to get key for each tab
export enum BUILDING_SETTING_KEYS {
    LUMBER_SETTINGS = 'lumber_settings',
    EXTERIOR_SETTINGS = 'exterior_settings',
    EXTERIOR_WASTE_FACTORS = 'exterior_waste_factors',
    WASTE_FACTORS = 'waste_factors',
    PROJECT_MATERIALS = 'project_materials',
    INFORMATION = 'information',
    EAVES_AND_GABLES_SETTINGS = 'eaves_and_gables_settings',
}

// used to get tab label
export enum buildingKeyToTabLabel {
    'lumber_settings' = 'Lumber',
    'exterior_settings' = 'Exterior Roofing',
    'exterior_waste_factors' = 'Exterior Waste Factors',
    'waste_factors' = 'Framing Waste Factors',
    'project_materials' = 'Framing Project Materials',
    'information' = 'Information',
    'eaves_and_gables_settings' = 'Eaves and Gables',
}

export const fieldsToFetchData = [
    BUILDING_SETTING_KEYS.LUMBER_SETTINGS,
    BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS,
    BUILDING_SETTING_KEYS.WASTE_FACTORS,
    BUILDING_SETTING_KEYS.PROJECT_MATERIALS,
    BUILDING_SETTING_KEYS.EAVES_AND_GABLES_SETTINGS,
]

// used to display specific field for option
export const BUILDING_SETTING_OPTION_KEYS = {
    SUB_FASCIA: 'sub_fascia',
    SOFFIT_TYPE: 'soffit_type',
    PLATE_OPTIONS: 'plate_option',
    NON_VENTED_EAVE: 'non_vented_eave',
    NON_VENTED_GABLE: 'non_vented_gable',
    VENTED_EAVE: 'vented_eave',
    VENTED_GABLE: 'vented_gable',
    GABLE_LADDER_FRAMING: 'gable_ladder_framing',
    INCLUDE_SUB_FASCIA: 'include_sub_fascia',
    REPLACE_ROOF_SHEATHING: 'replace_roof_sheathing',
    SLAB_ON_GRADE: 'slab_on_grade',
    GROUND_FLOOR_TREATED: 'ground_floor_treated',
    INCLUDE_APRON: 'include_apron',
    INCLUDE_SILL: 'include_sill',
    EAVE_BLOCKING: 'eave_blocking',
}

// used to get checkbox label in case it's not the same as key
export const fieldKeyToFieldLabel = {
    vented_eave: 'VENTED EAVE',
    vented_gable: 'VENTED GABLE',
    non_vented_eave: 'NON-VENTED EAVE',
    non_vented_gable: 'NON-VENTED GABLE',
    slab_on_grade: 'Is Slab On Grade',
    ground_floor_treated: 'Is Ground Floor Treated',
    include_apron: 'Interior Window Apron',
    include_sill: 'Interior Window Sill',
    gable_ladder_framing: 'Gable Ladder Framing',
}

/** FIELDS ORDER */

export const exteriorRoofingFieldsOrder = [
    'rafter_tail',
    'eave_blocking_width',
    'rafter_tail_size',
    'rafter_tail_length',
    'rafter_tail_spacing',
    'soffit_type',
    'soffit_tg_width',
    'soffit_vent_type',
    'gable_width',
    'non_vented_eave',
    'non_vented_gable',
    'hip_and_ridge_length',
    'fc_texture',
    'roof_vent',
    'lp_texture',
    'underlayment',
    'eave_width',
    'roof_starter',
    'valley_type',
]

export const exteriorWasteFactorFieldsOrder = [
    'roof_waste',
    'bb_shake_waste',
    'roof_accessories',
    'vinyl_trim_waste',
    'siding_waste',
    'default_trim_waste',
    'soffit_waste',
]

export const wasteFactorFieldsOrder = ['floor_waste', 'stud_waste', 'wall_waste', 'roof_waste']

export const projectMaterialsFieldsOrder = [
    'foundation_wall_foam_board_thickness',
    'crawlspace_wall_foam_board_thickness',
    'wall_sheathing_size',
    'slab_foam_thickness',
    'crawlspace_slab_foam_thickness',
    'wall_sheathing',
    'garage_slab_foam',
    'wall_sheathing_2',
    'plate_option',
    'gable_sheathing',
    'slab_on_grade',
    'roof_sheathing',
    'ground_floor_treated',
    'shearwall_sheathing',
    'sub_fascia',
    'house_wrap',
    'include_apron',
    'include_sill',
]

export const informationFieldsOrder = [
    'finished_basement_ceiling',
    'garage_area',
    'third_floor_area',
    'roof_area',
    'main_floor_area',
    'second_floor_area',
    'fourth_floor_area',
    'fifth_floor_area',
    'eave_length',
    'gable_area',
    'gable_length',
]

export const eavesAndGablesFieldsOrder = [
    'sub_fascia',
    'soffit_type',
    'soffit_vent_type',
    'panel_type',
    'texture',
    'profile',
    'gable_width',
    'gable_width_other',
    'vented_eave',
    'vented_gable',
    'eave_width',
    'gable_ladder_framing',
    'lumber_thickness',
    'lumber_width',
    'lumber_length',
    'rafter_tail',
    'rafter_tail_size',
    'rafter_tail_length',
    'rafter_tail_spacing',
    'vented_gable',
    'gable_width',
    'gable_width_other',
    'vented_eave',
    'eave_width',
    'eave_width_other',
    'eave_blocking',
    'eave_blocking_width',
    'lumber_length_rafter_tail',
    'replace_roof_sheathing',
    'lumber_thickness_soffit_type',
    'lumber_width_soffit_type',
    'lumber_length_soffit_type',
]

// fields order by tab
export const FIELDS_ORDER_BY_ACTIVE_BUILDING_TAB = {
    [BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS]: exteriorRoofingFieldsOrder,
    [BUILDING_SETTING_KEYS.EXTERIOR_WASTE_FACTORS]: exteriorWasteFactorFieldsOrder,
    [BUILDING_SETTING_KEYS.WASTE_FACTORS]: wasteFactorFieldsOrder,
    [BUILDING_SETTING_KEYS.PROJECT_MATERIALS]: projectMaterialsFieldsOrder,
    [BUILDING_SETTING_KEYS.EAVES_AND_GABLES_SETTINGS]: eavesAndGablesFieldsOrder,
    [BUILDING_SETTING_KEYS.INFORMATION]: informationFieldsOrder,
}

/** END OF FIELDS ORDER */

/** FIELD LABELS */

export enum BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS {
    sub_fascia = 'Sub Fascia',
    rafter_tail = 'Rafter Tail',
    eave_blocking_width = 'Eave Blocking Width',
    rafter_tail_size = 'Rafter Tail Size',
    rafter_tail_length = 'Rafter Tail Length',
    rafter_tail_spacing = 'Rafter Tail Spacing',
    soffit_type = 'Soffit Type',
    soffit_vent_type = 'Soffit Vent Type',
    non_vented_eave = 'Non-Vented Eave',
    non_vented_gable = 'Non-Vented Gable',
    fc_texture = 'Fiber Cement Texture',
    lp_texture = 'LP Texture',
    soffit_tg_width = 'Soffit TG Width',
    eave_width = 'Eave Width (In)',
    eave_width_other = 'Other Eave Width (In)',
    gable_width = 'Gable Width (In)',
    gable_width_other = 'Other Gable Width (In)',
    valley_type = 'Valley Type',
    hip_and_ridge_length = 'Hip and Ridge Length',
    roof_starter = 'Roof Starter',
    roof_vent = 'Roof Vent',
    underlayment = 'Underlayment',
}

export enum BUILDING_EXTERIOR_WASTE_FACTORS_SETTINGS_LABELS {
    roof_waste = 'Roof Waste',
    roof_accessories = 'Roof Accessories',
    siding_waste = 'Siding Waste',
    bb_shake_waste = 'BB/Shake Waste',
    vinyl_trim_waste = 'Vinyl Trim Waste',
    default_trim_waste = 'Default Trim Waste',
    soffit_waste = 'Soffit Waste',
}

export enum BUILDING_FRAMING_WASTE_FACTORS_SETTINGS_LABELS {
    basement_plate_ply = 'Basement Plate Ply',
    crawlspace_plate_ply = 'Crawlspace Plate Ply',
    main_plate_ply = 'Main Plate Ply',
    '2nd_plate_ply' = '2nd + Plate Ply',
    garage_plate_ply = 'Garage Plate Ply',
    floor_waste = 'Floor Waste',
    stud_waste = 'Stud Waste',
    wall_waste = 'Wall Waste',
    roof_waste = 'Roof Waste',
}

export enum BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS {
    foundation_wall_foam_board_thickness = 'Foundation Wall Foam Board Thickness',
    crawlspace_wall_foam_board_thickness = 'Crawlspace Wall Form Board Thickness',
    slab_foam_thickness = 'Slab Foam Thickness',
    crawlspace_slab_foam_thickness = 'Crawlspace Slab Foam Thickness',
    garage_slab_foam = 'Foundation Garage Slab Foam',
    plate_option = 'Plate Options',
    sub_fascia = 'Sub Fascia',
    roof_sheathing = 'Roof Sheathing',
    wall_sheathing_size = 'Wall Sheathing Size',
    wall_sheathing = 'Wall Sheathing',
    gable_sheathing = 'Gable Sheathing',
    wall_sheathing_2 = 'Wall Sheathing 2',
    shearwall_sheathing = 'Generic Shearwall Sheathing',
    slab_on_grade = 'Slab On Grade',
    ground_floor_treated = 'Ground Floor Treated',
    house_wrap = 'House Wrap',
    include_apron = 'Interior Window Apron',
    include_sill = 'Interior Window Sill',
}

export enum BUILDING_FRAMING_INFORMATION_LABELS {
    finished_basement_ceiling = 'Finished Basement Ceiling',
    garage_area = 'Garage Area',
    third_floor_area = '3rd Floor Area',
    roof_area = 'Roof Area',
    main_floor_area = 'Main Floor Area',
    second_floor_area = '2nd Floor Area',
    fourth_floor_area = '4th Floor Area',
    fifth_floor_area = '5th Floor Area',
    eave_length = 'Eave Length',
    gable_area = 'Gable Area',
    gable_length = 'Gable Length',
}

export enum BUILDING_EAVES_AND_GABLES_LABELS {
    sub_fascia = 'Sub Fascia',
    rafter_tail = 'Rafter Tail',
    rafter_tail_size = 'Rafter Tail Size',
    rafter_tail_length = 'Rafter Tail Length',
    rafter_tail_spacing = 'Rafter Tail Spacing',
    eave_blocking_width = 'Eave Blocking Width',
    soffit_type = 'Soffit Type',
    soffit_tg_width = 'Soffit TG Width',
    vented_gable = 'Vented Gable',
    gable_width = 'Gable Width (In)',
    gable_width_other = 'Gable Width (Other)',
    vented_eave = 'Vented Eave',
    eave_width = 'Eave Width (In)',
    eave_width_other = 'Eave Width (Other)',
    gable_ladder_framing = 'Gable Ladder Framing',
    replace_roof_sheathing = 'Replace Roof Sheathing',
    soffit_vent_type = 'Soffit Vent Type',
    eave_blocking = 'Eave Blocking',
    panel_type = 'Panel Type',
    texture = 'Texture',
    profile = 'Profile',
}

export const FIELD_LABELS_BY_ACTIVE_BUILDING_TAB = {
    [BUILDING_SETTING_KEYS.EXTERIOR_SETTINGS]: BUILDING_EXTERIOR_ROOFING_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.EXTERIOR_WASTE_FACTORS]: BUILDING_EXTERIOR_WASTE_FACTORS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.WASTE_FACTORS]: BUILDING_FRAMING_WASTE_FACTORS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.PROJECT_MATERIALS]: BUILDING_FRAMING_PROJECT_MATERIALS_SETTINGS_LABELS,
    [BUILDING_SETTING_KEYS.INFORMATION]: BUILDING_FRAMING_INFORMATION_LABELS,
    [BUILDING_SETTING_KEYS.EAVES_AND_GABLES_SETTINGS]: BUILDING_EAVES_AND_GABLES_LABELS,
}

/** END OF FIELD LABELS */

/** VALIDATION */
export const isSoffitWidthRequired = ['v groove', 'fine line', 'beaded ceiling']

export const soffitWidthEnabledOptions = [
    'cedar tg',
    'pine tg',
    'tg pat122',
    '#2yp pat122',
    'd yp tg spruce aqua seal',
    'v groove',
    'fine line',
    'beaded ceiling',
]

// options in soffit type which display conditional field soffit_vent_type
export const soffitTypeVentTypes = [
    '4X8 BC PLY',
    '4X8 FC',
    '4X8 PANEL CEDAR',
    '4X8 PLYBEAD',
    '4X8 PLYWOOD',
    '4X8 ROUGH SAWN',
    '4X8 LP SMARTFIBER (3/8)',
    '4X8 T111',
    'CEDAR TG',
    'CHANNEL RUSTIC CEDAR',
    'PANEL',
]

export const soffitTypeVentTypesV2 = ['dimensional lumber', 'panel (4x8)', 'fiber cement', 'lp']

export enum buildingSettingsValidationText {
    required = 'is required',
    between_0_99 = 'must be between 0 and 99',
}

// all field keys
export const FIELD_KEY = {
    EAVE_BLOCKING: 'eave_blocking',
    EAVE_BLOCKING_DIMENSIONS: 'eave_blocking_dimensions',
    EAVE_WIDTH: 'eave_width',
    EAVE_WIDTH_OTHER: 'eave_width_other',
    GABLE_WIDTH: 'gable_width',
    GABLE_WIDTH_OTHER: 'gable_width_other',
    SUB_FASCIA: 'sub_fascia',

    SOFFIT_TG_WIDTH: 'soffit_tg_width',
    SOFFIT_VENT_TYPE: 'soffit_vent_type',
    NON_VENTED_EAVE: 'non_vented_eave',
    NON_VENTED_GABLE: 'non_vented_gable',
    VENTED_EAVE: 'vented_eave',
    VENTED_GABLE: 'vented_gable',

    RAFTER_TAIL: 'rafter_tail',
    RAFTER_TAIL_FIELDS: 'rafter_tail_dimensions',
    RAFTER_TAIL_SIZE: 'rafter_tail_size',
    RAFTER_TAIL_LENGTH: 'rafter_tail_length',
    RAFTER_TAIL_SPACING: 'rafter_tail_spacing',
    EAVE_BLOCKING_WIDTH: 'eave_blocking_width',

    SOFFIT_TYPE: 'soffit_type',
    GABLE_LADDER_FRAMING: 'gable_ladder_framing',

    // dimensional fields
    LUMBER_THICKNESS: 'lumber_thickness',
    LUMBER_WIDTH: 'lumber_width',
    LUMBER_LENGTH: 'lumber_length',

    LUMBER_LENGTH_RAFTER_TAIL: 'lumber_length_rafter_tail',

    REPLACE_ROOF_SHEATHING: 'replace_roof_sheathing',
    PANEL_TYPE: 'panel_type',
    TEXTURE: 'texture',
    PROFILE: 'profile',

    LUMBER_THICKNESS_SOFFIT_TYPE: 'lumber_thickness_soffit_type',
    LUMBER_WIDTH_SOFFIT_TYPE: 'lumber_width_soffit_type',
    LUMBER_LENGTH_SOFFIT_TYPE: 'lumber_length_soffit_type',

    FASCIA_TYPE: 'fascia_type',
    FASCIA_TYPE_2: 'fascia_type_2',

    FRIEZE_TYPE: 'frieze_type',
    FRIEZE_TYPE_2: 'frieze_type_2',
}

export const fieldsThatHaveOtherValue: string[] = [FIELD_KEY.EAVE_WIDTH, FIELD_KEY.GABLE_WIDTH]

// fields which based on selection display other fields
export const fieldsWhichHaveConditionalFields = [FIELD_KEY.RAFTER_TAIL, FIELD_KEY.SOFFIT_TYPE]

// switch fields which will display dimension TWL field
export const fieldsSwitchWhichHaveConditionalFields: string[] = [
    FIELD_KEY.GABLE_LADDER_FRAMING,
    FIELD_KEY.SUB_FASCIA,
    FIELD_KEY.EAVE_BLOCKING,
]

export const RAFTER_TAIL_FIELDS = {
    eave_blocking_width: 'eave_blocking_width',
    rafter_tail_size: 'rafter_tail_size',
    rafter_tail_length: 'rafter_tail_length',
    rafter_tail_spacing: 'rafter_tail_spacing',
    rafter_tail_width: 'width',
    rafter_tail_dimensions: 'rafter_tail_dimensions',
}

export const SOFFIT_TYPE_FIELDS = {
    soffit_type: 'soffit_type',
    soffit_vent_type: 'soffit_vent_type',
}

// options fields are fields for which we get the data initially but do not render them, only when condition is match
export const optionalFields = [
    RAFTER_TAIL_FIELDS.eave_blocking_width,
    RAFTER_TAIL_FIELDS.rafter_tail_size,
    RAFTER_TAIL_FIELDS.rafter_tail_length,
    RAFTER_TAIL_FIELDS.rafter_tail_spacing,

    SOFFIT_TYPE_FIELDS.soffit_vent_type,

    FIELD_KEY.LUMBER_THICKNESS,
    FIELD_KEY.LUMBER_WIDTH,
    FIELD_KEY.LUMBER_LENGTH,

    FIELD_KEY.LUMBER_LENGTH_RAFTER_TAIL,
    FIELD_KEY.REPLACE_ROOF_SHEATHING,
    FIELD_KEY.PANEL_TYPE,
    FIELD_KEY.TEXTURE,
    FIELD_KEY.PROFILE,

    FIELD_KEY.LUMBER_THICKNESS_SOFFIT_TYPE,
    FIELD_KEY.LUMBER_WIDTH_SOFFIT_TYPE,
    FIELD_KEY.LUMBER_LENGTH_SOFFIT_TYPE,
]

export const EXTERIOR_SETTINGS_DEFAULT_ACTIVE_BOOLEAN_FIELDS = [BUILDING_SETTING_OPTION_KEYS.NON_VENTED_GABLE]

export const EXTERIOR_SETTINGS_DEFAULT_INACTIVE_BOOLEAN_FIELDS = [BUILDING_SETTING_OPTION_KEYS.NON_VENTED_EAVE]

// currently used under non_vented_eave_and_gable feature flag
export const EXTERIOR_SETTINGS_BOOLEAN_FIELDS = [
    ...EXTERIOR_SETTINGS_DEFAULT_ACTIVE_BOOLEAN_FIELDS,
    ...EXTERIOR_SETTINGS_DEFAULT_INACTIVE_BOOLEAN_FIELDS,
]

export const EAVES_AND_GABLES_BOOLEAN_FIELDS = [
    BUILDING_SETTING_OPTION_KEYS.SUB_FASCIA,
    BUILDING_SETTING_OPTION_KEYS.VENTED_EAVE,
    BUILDING_SETTING_OPTION_KEYS.VENTED_GABLE,
    BUILDING_SETTING_OPTION_KEYS.GABLE_LADDER_FRAMING,
    BUILDING_SETTING_OPTION_KEYS.REPLACE_ROOF_SHEATHING,
    BUILDING_SETTING_OPTION_KEYS.EAVE_BLOCKING,
]

export const EAVES_AND_GABLES_DEFAULT_ACTIVE_BOOLEAN_FIELDS = [
    BUILDING_SETTING_OPTION_KEYS.SUB_FASCIA,
    BUILDING_SETTING_OPTION_KEYS.VENTED_EAVE,
    BUILDING_SETTING_OPTION_KEYS.GABLE_LADDER_FRAMING,
    BUILDING_SETTING_OPTION_KEYS.EAVE_BLOCKING,
]

export const PROJECT_MATERIALS_DEFAULT_INACTIVE_BOOLEAN_FIELDS = [
    BUILDING_SETTING_OPTION_KEYS.SLAB_ON_GRADE,
    BUILDING_SETTING_OPTION_KEYS.GROUND_FLOOR_TREATED,
    BUILDING_SETTING_OPTION_KEYS.INCLUDE_APRON,
    BUILDING_SETTING_OPTION_KEYS.INCLUDE_SILL,
]

export const LENGTH_ENABLED_FIELDS = [RAFTER_TAIL_FIELDS.rafter_tail_dimensions]
