import { createAction } from '@reduxjs/toolkit'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { updateDocumentMapping } from '../../../api/takeoff-api'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import {
    selectDocumentChunks,
    selectDocumentMappings,
    updateActiveChunk,
    updateDocumentMappings,
} from '../../slices/documents'

export const completeTemplatePageAction = createAction<number>('completeTemplatePageAction')

export function* handleCompleteTemplatePageAction({ payload }: ReturnType<typeof completeTemplatePageAction>) {
    const documentMappings: DocumentMapping[] | null = yield select(selectDocumentMappings)
    const documentChunks: DocumentChunk[] | null = yield select(selectDocumentChunks)

    if (!documentMappings || !documentChunks) return

    const mapping = documentMappings.find((doc) => doc.id === payload)
    const chunk = documentChunks.find((chunk) => chunk.id === mapping?.document_chunk_id)

    if (!mapping || !chunk) return

    const sortedTemplateMappings = documentMappings
        .filter(
            (mapping) =>
                mapping.id !== payload &&
                mapping.is_option === false &&
                !mapping.additional_data.parentDocumentMapping &&
                mapping.is_estimation_complete === false
        )
        .sort((a, b) => a.id - b.id)

    try {
        yield call(updateDocumentMapping, chunk.project_document.project_id, {
            ...mapping,
            is_estimation_complete: true,
            updated_at: undefined,
            created_at: undefined,
            deleted_at: undefined,
        } as DocumentMapping)

        // Update the mapping in the store
        yield put(
            updateDocumentMappings([
                {
                    ...mapping,
                    is_estimation_complete: true,
                },
            ])
        )
    } catch (e) {
        yield call(console.error, e)
        yield put(updateDocumentMappings([mapping]))
    }

    if (sortedTemplateMappings.length === 0) {
        yield put(updateActiveChunk(null))

        return
    }

    const nextDocumentChunk = documentChunks.find((chunk) => chunk.id === sortedTemplateMappings[0].document_chunk_id)

    if (!nextDocumentChunk) return

    yield put(updateActiveChunk(nextDocumentChunk))
}

export function* watchForCompleteTemplatePage() {
    yield takeLatest(completeTemplatePageAction.type, handleCompleteTemplatePageAction)
}
