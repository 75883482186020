import { createAction } from '@reduxjs/toolkit'
import { call, delay, put, takeLatest } from 'redux-saga/effects'

import {
    bulkUpdateOpeningLocationCoordinatesAction,
    handleBulkUpdateOpeningLocationCoordinates,
} from './bulkUpdateOpeningLocationCoordinates'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { updateToolbarMessage } from '../../slices/common'
import { SAVING_CHANGES_ERROR_MESSAGE, TOOLBAR_MESSAGE_TIMER } from '../../types'

export const FLIP_DIRECTIONS = {
    Horizontal: 'h',
    Vertical: 'v',
}

export const bulkMirrorOpeningLocationsAction = createAction<string>('bulkMirrorOpeningLocationsAction')

export function* handleBulkMirrorOpeningLocations({ payload }: ReturnType<typeof bulkMirrorOpeningLocationsAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const [selectTool]: [Select] = yield call(manager.getTools, [Select.NAME])

    const multiSelectedGroup: paper.Group | null = yield call([selectTool, 'getMultiSelectedGroup'])

    if (!multiSelectedGroup) return

    switch (payload) {
        case FLIP_DIRECTIONS.Vertical:
            yield call([selectTool, 'mirrorItemVertically'], multiSelectedGroup)
            break
        case FLIP_DIRECTIONS.Horizontal:
            yield call([selectTool, 'mirrorItemHorizontally'], multiSelectedGroup)
            break
        default:
            break
    }

    yield call([selectTool, 'updateMultiSelectGroupSelectorRectangle'])

    try {
        yield call(handleBulkUpdateOpeningLocationCoordinates, {
            payload: selectTool.convertGroupChildrenToAPIUpdateData(multiSelectedGroup),
        } as ReturnType<typeof bulkUpdateOpeningLocationCoordinatesAction>)
    } catch (e) {
        yield call(console.error, e)

        switch (payload) {
            case FLIP_DIRECTIONS.Vertical:
                yield call([selectTool, 'mirrorItemVertically'], multiSelectedGroup)
                break
            case FLIP_DIRECTIONS.Horizontal:
                yield call([selectTool, 'mirrorItemHorizontally'], multiSelectedGroup)
                break
            default:
                break
        }

        yield call([selectTool, 'updateMultiSelectGroupSelectorRectangle'])
        yield put(updateToolbarMessage(SAVING_CHANGES_ERROR_MESSAGE))
        yield delay(TOOLBAR_MESSAGE_TIMER)
        yield put(updateToolbarMessage(null))
    }
}

export function* watchForBulkMirrorOpeningLocations() {
    yield takeLatest(bulkMirrorOpeningLocationsAction.type, handleBulkMirrorOpeningLocations)
}
