import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isString from 'lodash/isString'

import {
    DRAW_TOOL_ACTION,
    IMUP2DCoordinatesToUpdate,
    IMUP2DDrawableCutout,
    IMUP2DDrawableLocation,
    IMUPState,
    REGION_ENUMS,
} from '../types'
import { gotProjectAction } from './project'
import { SET_ACTIVE_FLOOR } from '../../actions/drawable'
import { Coordinate } from '../../models/activeDrawable'
import { GeneralDrawableSettings } from '../../models/activeDrawableSettings'
import { ActiveFloor } from '../../models/activeFloor'
import { AIAutomatedObject, AIMaterialByFloor } from '../../models/aiClassifications'
import { Project } from '../../models/project'
import { PreparedTakeoffData } from '../../models/takeoff'
import { DRAWABLE_TYPES } from '../../shared/constants/drawable-types'
import { JOIST_DIRECTION } from '../../shared/constants/joist-directions'
import { RootState } from '../../stores'
import { convertScaleFactorLabelEnumToDecimal } from '../../utils/calculations/scaleConversion/scaleConversion'

export type State2D = {
    selectedItem: paper.Item['id'] | null
    altSelectedItems: paper.Item['id'][] | null
    imagePaperId: paper.Raster['id'] | null
    openingGroupId: number | null
    documentChunkIds: number[]
    drawableLocations: IMUP2DDrawableLocation[]
    drawablesToCreate: number[]
    drawablesToDelete: number[]
    highlightsToCreate: number[]
    drawablesToCreateByTool: number[]
    errors: string[]
    activeDrawableId: number | null
    activeOpeningLocationId: number | null
    coordinatesToUpdate: IMUP2DCoordinatesToUpdate | null
    zoomButtonIncrement: number
    scaleFactor: number
    regionId: number | null
    defaultZoom: number
    defaultCenter: number[]
    editingActive: boolean
    exiting: boolean
    currentZoom: number
    selectedMaterialType: DRAWABLE_TYPES | REGION_ENUMS | null
    classificationErrorMessage: string | null
    maxDimension: number
    drawToolCreationFlag: DRAW_TOOL_ACTION
    newCreatedDrawables: number[]
    joistDirectionOtherCoordinates: string | null
    joistDirection: JOIST_DIRECTION | null
    aiSuggestions: AIAutomatedObject[] | null
    forceOpenAIFolders: boolean
    takeoffData: PreparedTakeoffData | {}
    selectedAiSuggestionIds: string[]
    activeHighlightId: number | null
    currentOverlayChunkId: number | null
    currentEstimatedOptionOverlayChunkId: number | null
    overlayChunkOpacityDictionary: Record<number, number>
    currentOverlayColor: string
    drawableIdsClipboard: number[]
    multiSelectedDrawableIds: number[]
    lastOverlayInsertTime: number | null
    activeAISuggestionSettings: GeneralDrawableSettings | null
    hiddenAIMaterialGroups: AIMaterialByFloor[]
    plansTreePageVisibilityDictionary: Record<number, boolean>
}

/**
 * See for explanation of values: https://www.archisoup.com/architectural-scale
 */
export const scaleMap = {
    '1:2': 25,
    '1:4': 50,
    '1:8': 100,
    default: 25,
}

export const initial2DState: State2D = {
    selectedItem: null,
    altSelectedItems: null,
    imagePaperId: null,
    documentChunkIds: [],
    drawableLocations: [],
    drawablesToCreate: [],
    drawablesToDelete: [],
    highlightsToCreate: [],
    drawablesToCreateByTool: [],
    openingGroupId: null,
    errors: [],
    activeDrawableId: null,
    zoomButtonIncrement: 0.5,
    activeOpeningLocationId: null,
    coordinatesToUpdate: null,
    scaleFactor: scaleMap.default,
    regionId: null,
    defaultZoom: 1,
    defaultCenter: [0, 0],
    editingActive: false,
    exiting: false,
    currentZoom: 1,
    selectedMaterialType: null,
    classificationErrorMessage: null,
    maxDimension: 0,
    drawToolCreationFlag: DRAW_TOOL_ACTION.NEW_OPENING,
    newCreatedDrawables: [],
    joistDirectionOtherCoordinates: null,
    joistDirection: null,
    aiSuggestions: null,
    forceOpenAIFolders: false,
    takeoffData: {},
    selectedAiSuggestionIds: [],
    activeHighlightId: null,
    currentOverlayChunkId: null,
    currentEstimatedOptionOverlayChunkId: null,
    overlayChunkOpacityDictionary: {},
    currentOverlayColor: '#0086C9',
    drawableIdsClipboard: [],
    multiSelectedDrawableIds: [],
    lastOverlayInsertTime: null,
    activeAISuggestionSettings: null,
    hiddenAIMaterialGroups: [],
    plansTreePageVisibilityDictionary: {},
}

export const getCurrentDrawableLocations = createSelector(
    (state: IMUPState) => {
        return state['2D'].drawableLocations
    },
    (drawableLocations) => drawableLocations
)

export const getCurrentDrawableLocationsFromRootState = createSelector(
    (state: RootState) => {
        return state.IMUP['2D'].drawableLocations
    },
    (drawableLocations) => drawableLocations
)

export const getOpacities = createSelector(
    (state: RootState) => {
        return { lineOpacity: state.IMUP.tools.lineOpacityValue, areaOpacity: state.IMUP.tools.areaOpacityValue }
    },
    (opacities) => opacities
)

export const drawableLocationSelector = createSelector(
    (state: RootState): IMUP2DDrawableLocation[] => getCurrentDrawableLocations(state.IMUP),
    (state: IMUP2DDrawableLocation[]): IMUP2DDrawableLocation[] => state
)

type PaperSelectionActionPayload = {
    selectedItem: number | null
    activeDrawableId: number | null
    activeOpeningLocationId: number | null
    openingGroupId: number | null
}

const handleSelectedPaperItemAction = (
    state: State2D,
    { payload }: PayloadAction<PaperSelectionActionPayload>
): void => {
    state.selectedItem = payload.selectedItem
    state.activeDrawableId = payload.activeDrawableId
    state.activeOpeningLocationId = payload.activeOpeningLocationId
    state.openingGroupId = payload.openingGroupId
}

const handleScaleFactor = (state: State2D, { payload }: PayloadAction<ActiveFloor | { scale_factor: number }>) => {
    if (isString(payload.scale_factor)) {
        state.scaleFactor = convertScaleFactorLabelEnumToDecimal(payload.scale_factor ?? '')
    } else {
        state.scaleFactor = payload.scale_factor
    }
}

const handleResetVisibilityOnProjectChange = (state: State2D, _: PayloadAction<Project>) => {
    state.plansTreePageVisibilityDictionary = {}
}

const handleUpdateRegionId = (state: State2D, { payload }: PayloadAction<{ regionId: number | null }>) => {
    state.regionId = payload.regionId
}

const handleUpdateSelectedPaperId = (state: State2D, { payload }: PayloadAction<number | null>) => {
    state.selectedItem = payload
}

function handleEditing(state: State2D, { payload }: PayloadAction<boolean>) {
    state.editingActive = payload
}

function handleSetDefaultZoom(state: State2D, action: PayloadAction<number>): void {
    state.defaultZoom = action.payload
}

function handleSetDefaultCenter(state: State2D, action: PayloadAction<number[]>): void {
    state.defaultCenter = action.payload
}

const handleCurrentZoom = (state: State2D, { payload }: PayloadAction<number>) => {
    state.currentZoom = payload
}

const handleSetMaxDimension = (state: State2D, { payload }: PayloadAction<number>) => {
    state.maxDimension = payload
}

const handleSetFlag = (state: State2D, { payload }: PayloadAction<DRAW_TOOL_ACTION>) => {
    state.drawToolCreationFlag = payload
}

const handleSetClassificationErrorMessage = (state: State2D, { payload }: PayloadAction<string | null>) => {
    state.classificationErrorMessage = payload
}

export function preparedDrawablesSuccessAction(
    state: State2D,
    action: PayloadAction<{ documentChunkIds: number[]; drawableLocations: IMUP2DDrawableLocation[] }>
): void {
    state.documentChunkIds = action.payload.documentChunkIds
    state.drawableLocations = action.payload.drawableLocations
}

function handleSetDrawableLocations(state: State2D, action: PayloadAction<IMUP2DDrawableLocation[]>): void {
    state.drawableLocations = action.payload
}

function handleSetJoistDirectionOther(state: State2D, action: PayloadAction<string | null>): void {
    state.joistDirectionOtherCoordinates = action.payload
}

function handleSetJoistDirection(state: State2D, action: PayloadAction<JOIST_DIRECTION | null>): void {
    state.joistDirection = action.payload
}

function insertDrawableLocationAction(state: State2D, action: PayloadAction<IMUP2DDrawableLocation>): void {
    state.drawableLocations = [...state.drawableLocations, action.payload]
}

function updateDrawableLocationCoordinatesAction(
    state: State2D,
    action: PayloadAction<{
        opening_location_id: number
        coordinates: Coordinate[]
        linear_total?: number | null
        quantity: number | null
        cutouts?: IMUP2DDrawableCutout[]
        region_id: number | null
    }>
): void {
    const locationToUpdateId = action.payload.opening_location_id

    state.drawableLocations = state.drawableLocations.map((originalLocation) => {
        if (locationToUpdateId === originalLocation.opening_location_id) {
            const updatedLocation = {
                ...originalLocation,
                additionalData: {
                    ...originalLocation.additionalData,
                },
                coordinates: action.payload.coordinates,
                cutouts: action.payload.cutouts ?? originalLocation.cutouts,
                settings: {
                    ...originalLocation.settings,
                    linear_total: action.payload.linear_total || originalLocation.settings.linear_total,
                    quantity: action.payload.quantity,
                },
                region_id: action.payload.region_id,
            }

            return updatedLocation
        }

        return originalLocation
    })
}

function handleCreateDrawableLocation(state: State2D, action: PayloadAction<number>): void {
    state.drawablesToCreate = [...state.drawablesToCreate, action.payload]
}

function handleCreateDrawableByTool(state: State2D, action: PayloadAction<number>): void {
    state.drawablesToCreateByTool = [...state.drawablesToCreateByTool, action.payload]
}

function handleResetNewCreateDrawableByTool(state: State2D): void {
    state.drawablesToCreateByTool = []
}

function handleNewCreatedDrawables(state: State2D, action: PayloadAction<number>): void {
    state.newCreatedDrawables = [...state.newCreatedDrawables, action.payload]
}

function handleResetNewCreatedDrawables(state: State2D): void {
    state.newCreatedDrawables = []
}

function handleDeleteDrawableLocation(state: State2D, action: PayloadAction<number[]>): void {
    // reset the state to clear selection since the deletion will remove the selected drawable
    state.activeDrawableId = null
    state.openingGroupId = null
    state.activeOpeningLocationId = null
    state.selectedItem = null
    state.drawableLocations = [...state.drawableLocations].filter((l) => !action.payload.includes(l.drawable_id))
}

function handleDeleteAISuggestions(state: State2D, action: PayloadAction<string[]>): void {
    state.aiSuggestions = (state.aiSuggestions ?? []).map((aiSuggestion) => {
        if (action.payload.includes(aiSuggestion.id)) {
            return {
                ...aiSuggestion,
                isDeleted: true,
            }
        }

        return aiSuggestion
    })
}

function handleUnsetActiveDrawableId(state: State2D): void {
    state.activeDrawableId = null
}

function handleDeleteOpeningLocation(state: State2D, action: PayloadAction<number>): void {
    state.drawableLocations = [...state.drawableLocations].filter((l) => l.opening_location_id !== action.payload)
}

function handleGetDrawablesFailure(state: State2D, action: PayloadAction<string>): void {
    state.documentChunkIds = []
    state.drawableLocations = []
    state.errors = [...state.errors, action.payload]
}

function handleErrorReset(state: State2D): void {
    state.errors = []
}

function handleCoordinatesUpdate(state: State2D, { payload }: PayloadAction<IMUP2DCoordinatesToUpdate>): void {
    state.coordinatesToUpdate = {
        coordinates: payload.coordinates,
        measurements: payload.measurements,
        cutouts: payload.cutouts,
    }
}

function handleUpdatedCoordinatesReset(state: State2D): void {
    state.coordinatesToUpdate = null
    state.editingActive = false
}

function handleUpdateSelectedMaterialType(
    state: State2D,
    { payload }: PayloadAction<State2D['selectedMaterialType']>
): void {
    state.selectedMaterialType = payload
}

function handleCreateHighlightLocation(state: State2D, { payload }: PayloadAction<number>): void {
    state.highlightsToCreate = [...state.highlightsToCreate, payload]
}

function handleClearHighlightsToCreate(state: State2D) {
    state.highlightsToCreate = []
}

const handleAiSuggestionsDrawables = (state: State2D, { payload }: PayloadAction<AIAutomatedObject[]>) => {
    state.aiSuggestions = payload
}

const handleSetAltSelectedItems = (state: State2D, { payload }: PayloadAction<number[] | null>) => {
    state.altSelectedItems = payload
}

const handleSetTakeoffData = (state: State2D, { payload }: PayloadAction<PreparedTakeoffData>) => {
    const { projectId, takeoffData } = payload

    state.takeoffData[projectId] = takeoffData
}

function handleSetSelectedAiSuggestionIds(state: State2D, action: PayloadAction<string>): void {
    state.selectedAiSuggestionIds = [...state.selectedAiSuggestionIds, action.payload]
}

function handleSetActiveAISuggestionSettings(
    state: State2D,
    action: PayloadAction<GeneralDrawableSettings | null>
): void {
    state.activeAISuggestionSettings = action.payload
}

function handleDeleteSelectedAiSuggestionIds(state: State2D, action: PayloadAction<string>): void {
    state.selectedAiSuggestionIds = [...state.selectedAiSuggestionIds].filter((aiID) => aiID !== action.payload)
}

function handleClearSelectedAiSuggestionIds(state: State2D): void {
    state.selectedAiSuggestionIds = []
}

function handleSetActiveHighlightId(state: State2D, action: PayloadAction<number | null>): void {
    state.activeHighlightId = action.payload
}

function handleSetOverlayChunkID(state: State2D, action: PayloadAction<number | null>): void {
    state.lastOverlayInsertTime = action ? Date.now() : null
    state.currentOverlayChunkId = action.payload
}

function handleSetEstimatedOptionOverlayChunkId(state: State2D, action: PayloadAction<number | null>): void {
    state.currentEstimatedOptionOverlayChunkId = action.payload
}

function handleSetOverlayOpacity(state: State2D, action: PayloadAction<{ id: number; opacity: number }>): void {
    const { id, opacity } = action.payload

    state.overlayChunkOpacityDictionary[id] = opacity
}

function handleSetOverlayColor(state: State2D, action: PayloadAction<{ id: number; color: string }>): void {
    const { color } = action.payload

    state.currentOverlayColor = color
}

function handleCopyDrawables(state: State2D, action: PayloadAction<number[]>) {
    state.drawableIdsClipboard = action.payload
}

function handleMultiSelectingDrawables(state: State2D, action: PayloadAction<number[]>) {
    state.multiSelectedDrawableIds = action.payload
}

function handleForceOpenAIFolders(state: State2D, action: PayloadAction<boolean>) {
    state.forceOpenAIFolders = action.payload
}

function handleSetHiddenAIMaterialGroups(
    state: State2D,
    action: PayloadAction<{ group: AIMaterialByFloor; hidden: boolean }>
) {
    if (!action.payload.hidden) {
        state.hiddenAIMaterialGroups = [...state.hiddenAIMaterialGroups, action.payload.group]
    } else {
        state.hiddenAIMaterialGroups = [...state.hiddenAIMaterialGroups].filter(
            (group) => group.name !== action.payload.group.name
        )
    }
}

function handleUpdatingPlansTreePageVisibility(
    state: State2D,
    action: PayloadAction<{ id: number; visibility: boolean }>
) {
    const { payload } = action
    const { id, visibility } = payload

    state.plansTreePageVisibilityDictionary[id] = visibility
}

const reducers = {
    selectedPaperItem: handleSelectedPaperItemAction,
    preparedDrawablesSuccess: preparedDrawablesSuccessAction,
    reset2DErrors: handleErrorReset,
    preparedDrawablesFailure: handleGetDrawablesFailure,
    setDefaultZoom: handleSetDefaultZoom,
    setDefaultCenter: handleSetDefaultCenter,
    updateSelectedCoordinates: handleCoordinatesUpdate,
    insertDrawableLocation: insertDrawableLocationAction,
    resetUpdatedCoordinates: handleUpdatedCoordinatesReset,
    updateDrawableLocationCoordinates: updateDrawableLocationCoordinatesAction,
    createDrawableLocation: handleCreateDrawableLocation,
    deleteDrawableLocation: handleDeleteDrawableLocation,
    deleteOpeningLocation: handleDeleteOpeningLocation,
    editingActive: handleEditing,
    setCurrentZoom: handleCurrentZoom,
    updateSelectedMaterialType: handleUpdateSelectedMaterialType,
    updateSelectedPaperId: handleUpdateSelectedPaperId,
    updateScaleFactor: handleScaleFactor,
    updateRegionId: handleUpdateRegionId,
    createHighlight: handleCreateHighlightLocation,
    clearHighlightsToCreate: handleClearHighlightsToCreate,
    setClassificationErrorMessage: handleSetClassificationErrorMessage,
    setMaxDimension: handleSetMaxDimension,
    setFlag: handleSetFlag,
    setNewCreatedDrawables: handleNewCreatedDrawables,
    resetNewCreatedDrawables: handleResetNewCreatedDrawables,
    setDrawableLocations: handleSetDrawableLocations,
    setJoistDirectionOther: handleSetJoistDirectionOther,
    setJoistDirection: handleSetJoistDirection,
    setAiSuggestions: handleAiSuggestionsDrawables,
    setAltSelectedItems: handleSetAltSelectedItems,
    setTakeoffData: handleSetTakeoffData,
    unsetActiveDrawableId: handleUnsetActiveDrawableId,
    createDrawableByTool: handleCreateDrawableByTool,
    resetNewCreatedDrawableByTool: handleResetNewCreateDrawableByTool,
    setSelectedAiSuggestionIds: handleSetSelectedAiSuggestionIds,
    clearSelectedAiSuggestionIds: handleClearSelectedAiSuggestionIds,
    deleteSelectedAiSuggestionIds: handleDeleteSelectedAiSuggestionIds,
    setActiveHighlightId: handleSetActiveHighlightId,
    setOverlayChunkId: handleSetOverlayChunkID,
    setEstimatedOptionOverlayChunkId: handleSetEstimatedOptionOverlayChunkId,
    updateOverlayOpacity: handleSetOverlayOpacity,
    updateOverlayColor: handleSetOverlayColor,
    copyDrawables: handleCopyDrawables,
    multiSelectDrawables: handleMultiSelectingDrawables,
    setForceOpenAIFolders: handleForceOpenAIFolders,
    deleteAISuggestions: handleDeleteAISuggestions,
    setAiSuggestionSettings: handleSetActiveAISuggestionSettings,
    setHiddenAIMaterialGroups: handleSetHiddenAIMaterialGroups,
    updatePlansTreePageVisibility: handleUpdatingPlansTreePageVisibility,
}

const setActiveFloor = createAction<ActiveFloor>(SET_ACTIVE_FLOOR)

const extraReducers = (builder: ActionReducerMapBuilder<State2D>): void => {
    builder.addCase(setActiveFloor, handleScaleFactor)
    // Reset the plans tree state on project change
    builder.addCase(gotProjectAction, handleResetVisibilityOnProjectChange)
}

const slice2D = createSlice({
    name: '2D',
    initialState: initial2DState,
    reducers,
    extraReducers,
})

export const {
    selectedPaperItem,
    preparedDrawablesSuccess,
    preparedDrawablesFailure,
    reset2DErrors,
    setDefaultZoom,
    setDefaultCenter,
    updateSelectedCoordinates,
    resetUpdatedCoordinates,
    updateDrawableLocationCoordinates,
    createDrawableLocation,
    deleteDrawableLocation,
    deleteOpeningLocation,
    insertDrawableLocation,
    editingActive,
    setCurrentZoom,
    updateSelectedMaterialType,
    updateSelectedPaperId,
    updateScaleFactor,
    updateRegionId,
    createHighlight,
    clearHighlightsToCreate,
    setClassificationErrorMessage,
    setMaxDimension,
    setFlag,
    setNewCreatedDrawables,
    resetNewCreatedDrawables,
    setDrawableLocations,
    setJoistDirectionOther,
    setJoistDirection,
    setAiSuggestions,
    setAltSelectedItems,
    setTakeoffData,
    unsetActiveDrawableId,
    createDrawableByTool,
    resetNewCreatedDrawableByTool,
    setSelectedAiSuggestionIds,
    clearSelectedAiSuggestionIds,
    deleteSelectedAiSuggestionIds,
    setActiveHighlightId,
    setOverlayChunkId,
    setEstimatedOptionOverlayChunkId,
    updateOverlayOpacity,
    updateOverlayColor,
    copyDrawables,
    multiSelectDrawables,
    setForceOpenAIFolders,
    deleteAISuggestions,
    setAiSuggestionSettings,
    setHiddenAIMaterialGroups,
    updatePlansTreePageVisibility,
} = slice2D.actions

export const getSelectedMaterial = createSelector(
    (state: RootState) => state.IMUP['2D'].selectedMaterialType,
    (matType) => matType
)

export const getCreationFlag = createSelector(
    (state: RootState) => state.IMUP['2D'].drawToolCreationFlag,
    (drawToolCreationFlag) => drawToolCreationFlag
)

export const selectScaleFactor = createSelector(
    (state: RootState) => state.IMUP['2D'].scaleFactor,
    (scaleFactor) => scaleFactor
)

export const selectRegionId = createSelector(
    (state: RootState) => state.IMUP['2D'].regionId,
    (regionId) => regionId
)

export const selectNewCreatedDrawables = createSelector(
    (state: RootState) => state.IMUP['2D'].newCreatedDrawables,
    (newCreatedDrawables) => newCreatedDrawables
)

export const selectClassificationErrorMessage = createSelector(
    (state: RootState) => state.IMUP['2D'].classificationErrorMessage,
    (classificationErrorMessage) => classificationErrorMessage
)

export const selectDrawableLocationsForPage = createSelector(
    (state: RootState) =>
        state.IMUP['2D'].drawableLocations.filter(
            (l) => l.document_chunk_id === state.IMUP['documents'].activeDocumentChunkId
        ),
    (locations) => locations
)

export const selectJoistDirectionOther = createSelector(
    (state: RootState) => state.IMUP['2D'].joistDirectionOtherCoordinates,
    (joistDirectionOtherCoordinates) => joistDirectionOtherCoordinates
)

export const selectJoistDirection = createSelector(
    (state: RootState) => state.IMUP['2D'].joistDirection,
    (joistDirection) => joistDirection
)

export const selectAiSuggestions = createSelector(
    (state: RootState) => state.IMUP['2D'].aiSuggestions,
    (aiSuggestions) => aiSuggestions
)

export const selectAltSelectedItems = createSelector(
    (state: RootState) => state.IMUP['2D'].altSelectedItems,
    (altSelectedItems) => altSelectedItems
)

export const selectDrawableLocations = createSelector(
    (state: RootState) => state.IMUP['2D'].drawableLocations,
    (drawableLocations) => drawableLocations
)

/** Return all automated_objects per document_mapping */
export const selectAiSuggestionsForPage = createSelector(
    (state: RootState, activeFloorId: number) =>
        state.IMUP['2D'].aiSuggestions?.filter((ai) => ai.document_mapping_id === activeFloorId),
    (aiSuggestion) => aiSuggestion
)

export const selectTakeoffData = createSelector(
    (state: RootState) => state.IMUP['2D'].takeoffData,
    (takeoffData) => takeoffData
)

export const selectCoordinatesToUpdate = createSelector(
    (state: RootState) => state.IMUP['2D'].coordinatesToUpdate,
    (coordinatesToUpdate) => coordinatesToUpdate
)

export const selectDrawablesToCreateByTool = createSelector(
    (state: RootState) => state.IMUP['2D'].drawablesToCreateByTool,
    (drawablesToCreateByTool) => drawablesToCreateByTool
)

export const selectedAiSuggestionIds = createSelector(
    (state: RootState) => state.IMUP['2D'].selectedAiSuggestionIds,
    (selectedAiSuggestionIds) => selectedAiSuggestionIds
)

export const selectCurrentOverlayChunkId = createSelector(
    (state: RootState) => state.IMUP['2D'].currentOverlayChunkId,
    (chunkId) => chunkId
)

export const selectOpacityDict = createSelector(
    (state: RootState) => state.IMUP['2D'].overlayChunkOpacityDictionary,
    (opacityDict) => opacityDict
)

export const selectLastOverlayInsertTime = createSelector(
    (state: RootState) => state.IMUP['2D'].lastOverlayInsertTime,
    (timeStamp) => timeStamp
)

export const selectOverlayColor = createSelector(
    (state: RootState) => state.IMUP['2D'].currentOverlayColor,
    (overlayColor) => overlayColor
)

export const selectCurrentEstimatedOptionOverlayChunkId = createSelector(
    (state: RootState) => state.IMUP['2D'].currentEstimatedOptionOverlayChunkId,
    (currentEstimatedOptionOverlayChunkId) => currentEstimatedOptionOverlayChunkId
)

export const selectMultiSelectedGroupNamesAndIds = createSelector(
    [
        (state: RootState) => state.IMUP.geometry.geometries,
        (state: RootState) => state.IMUP['2D'].multiSelectedDrawableIds,
    ],
    (geometries, openingLocationIds) => {
        return geometries.reduce((groupsRecord, geo) => {
            if (openingLocationIds.includes(geo.id)) {
                groupsRecord[geo.geometryGroupId] = geo.settings.name ?? ''
            }

            return groupsRecord
        }, {} as Record<number, string>)
    }
)

export const selectCopiedIds = createSelector(
    (state: RootState) => state.IMUP['2D'].drawableIdsClipboard,
    (ids) => ids
)

export const selectMapDrawableLocationsByOpeningLocationId = createSelector(
    (state: RootState) => state.IMUP['2D'].drawableLocations,
    (drawableLocations) => {
        return new Map(drawableLocations.map((location) => [location.opening_location_id, location]))
    }
)

export const selectForceOpenAIFolders = createSelector(
    (state: RootState) => state.IMUP['2D'].forceOpenAIFolders,
    (forceOpenAIFolders) => forceOpenAIFolders
)

export const selectActiveAISuggestionSettings = createSelector(
    (state: RootState) => state.IMUP['2D'].activeAISuggestionSettings,
    (settings) => settings
)

export const selectHiddenAIMaterialGroups = createSelector(
    (state: RootState) => state.IMUP['2D'].hiddenAIMaterialGroups,
    (hiddenAIMaterialGroups) => hiddenAIMaterialGroups
)

export const selectHiddenAIIds = createSelector(
    [
        (state: RootState) => state.IMUP['2D'].hiddenAIMaterialGroups,
        (state: RootState) => state.IMUP['2D'].aiSuggestions,
    ],
    (hiddenAIGroups, aiSuggestions) => {
        return (
            aiSuggestions?.reduce<string[]>((acc, aiSuggestion) => {
                if (
                    hiddenAIGroups.some((material) =>
                        material.aiMaterials.some((aiMaterial) => aiMaterial.id === aiSuggestion.id)
                    )
                ) {
                    acc.push(aiSuggestion.id)
                }

                return acc
            }, []) ?? []
        )
    }
)
export default slice2D
