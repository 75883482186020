import defaultTo from 'lodash/defaultTo'
import isNull from 'lodash/isNull'
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects'

import createPathItemFromType, { getScaleFactor } from './createPathItemFromType'
import addMetadataToPath from './data-prep/addMetadataToPath'
import { ActiveFloor } from '../../../models/activeFloor'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { drawableBackgroundAndBorderColor } from '../../../shared/services/drawable-color-services'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color } from '../../lib/toolBoxes/2D'
import WorkspaceTool from '../../lib/toolBoxes/2D/tools/workspace/Workspace.tool'
import addSelectFunctionalityToModifications from '../../lib/utils/functionality-bindings/addSelectFunctionalityToModifications'
import { selectMapDrawableLocationsByOpeningLocationId } from '../../slices/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { IMUP2DDrawableLocation, ITool } from '../../types'

type DrawConflictByTypeYield =
    | CallEffect<
          string | paper.Color | paper.Path | null | PaperManager | ITool[] | void | Map<number, IMUP2DDrawableLocation>
      >
    | SelectEffect
    | (IMUP2DDrawableLocation | undefined)

type DrawConflictByTypeNext = paper.Color &
    string &
    paper.Path &
    managerTools &
    PaperManager &
    ActiveFloor &
    Map<number, IMUP2DDrawableLocation> &
    (IMUP2DDrawableLocation | undefined)

type managerTools = [Color, WorkspaceTool]

export default function* drawConflictByType(
    materialModificationConflict: IMaterialModificationConflict,
    areaOpacity: number,
    lineOpacity: number,
    regionPaths: paper.Path[]
): Generator<DrawConflictByTypeYield, paper.Path | null, DrawConflictByTypeNext> {
    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return null

    const mapDrawableLocations: ReturnType<typeof selectMapDrawableLocationsByOpeningLocationId> = yield select(
        selectMapDrawableLocationsByOpeningLocationId
    )

    const assignedLocation: IMUP2DDrawableLocation | undefined = yield mapDrawableLocations.get(
        materialModificationConflict.material_location_id
    )

    if (!assignedLocation) return null

    const { drawing_type, settings, additionalData, ai_suggestion_id } = assignedLocation

    const [colorTool, workspaceTool]: managerTools = yield call(manager.getTools, [Color.NAME, WorkspaceTool.NAME])

    // determine color by location settings and drawing_type
    const colorValueFromConstants: string = yield call(
        drawableBackgroundAndBorderColor,
        defaultTo(drawing_type, ''),
        settings.name,
        settings.selection,
        settings.location,
        additionalData,
        settings.material,
        ai_suggestion_id
    )

    const shapeColor: ReturnType<typeof colorTool.createColor> = yield call(
        colorTool.createColor,
        colorValueFromConstants
    )

    const path: paper.Path | null = yield call(
        createPathItemFromType,
        assignedLocation,
        materialModificationConflict.coordinates,
        areaOpacity,
        lineOpacity,
        shapeColor,
        regionPaths
    )

    const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)

    if (!activeFloor) return null

    const scaleFactor = getScaleFactor(
        materialModificationConflict.coordinates,
        workspaceTool,
        activeFloor,
        regionPaths
    )

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, materialModificationConflict)
    path.data.isModification = true
    yield call(addSelectFunctionalityToModifications, path)

    return path
}
