import { createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { fork, put, select, takeEvery } from 'redux-saga/effects'

import {
    SET_ACTIVE_DRAWABLE_GROUP,
    SET_ACTIVE_DRAWABLE_GROUP_FROM_ID,
    setActiveDrawableGroupFromID,
    TOGGLE_ACTIVE_DRAWABLE,
} from '../../../actions/drawable'
import { RootState } from '../../../stores'
import { selectActiveMode } from '../../slices/common'
import { selectActiveGeometryGroup, selectDrawableGroupsGeometriesHash } from '../../slices/geometry'
import { VIEW_MODE } from '../../types'
import { updateSelectedPaperAndCutoutItemsFromSelectedIDs } from '../2D/updateSelectedAndCutoutItems'

export const selectDrawableGroupsAndActiveOpeningGroup = createSelector(
    (state: RootState) => ({
        drawableGroups: selectDrawableGroupsGeometriesHash(state, true),
        currentActiveOpeningGroupId: selectActiveGeometryGroup(state)?.id ?? null,
    }),
    (state) => state
)

export function* handleSetActiveDrawableGroupFromId(action: ReturnType<typeof setActiveDrawableGroupFromID>) {
    try {
        const activeMode = yield select(selectActiveMode)
        // IF the active mode is 2D then we should update the cutout and selected paper items

        if (activeMode === VIEW_MODE.Markup2D) {
            yield fork(updateSelectedPaperAndCutoutItemsFromSelectedIDs, action)
        }

        const { drawableGroups, currentActiveOpeningGroupId } = yield select(selectDrawableGroupsAndActiveOpeningGroup)

        const activeDrawableGroup = yield drawableGroups[action.payload.openingGroupId]

        // Only update the active drawable group if it is different from the currently selected one
        if (!isEqual(action.payload.openingGroupId, currentActiveOpeningGroupId)) {
            yield put({
                type: SET_ACTIVE_DRAWABLE_GROUP,
                payload: { activeDrawableGroup, activeDrawableId: action.payload.activeDrawableId },
            })
        } else {
            yield put({
                type: TOGGLE_ACTIVE_DRAWABLE,
                payload: { drawable_id: action.payload.activeDrawableId },
            })
        }
    } catch (error) {
        console.error(error)
    }
}

export function* watchForSetActiveDrawableGroupFromId() {
    yield takeEvery(SET_ACTIVE_DRAWABLE_GROUP_FROM_ID, handleSetActiveDrawableGroupFromId)
}
