import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { call, CallEffect, PutEffect, select, SelectEffect } from 'redux-saga/effects'

import { cutoutAreas } from './cutoutAreas'
import { updateDoubleJoistAction, updateJoistLabelGroupStyle } from './updateDoubleJoistLines'
import { convertAbsoluteDistanceToFeetAndInches } from '../../../components/markup/utils/helpers'
import { Coordinate } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { AISuggestion } from '../../../models/aiClassifications'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import IndexableObject from '../../../shared/constants/general-enums/indexableObject'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { isHeaderAndBeamGroup } from '../../../shared/services/drawable-groups-service'
import store from '../../../stores'
import {
    calculatePXPerFootXandY,
    convertScaleFactorLabelEnumToDecimal,
} from '../../../utils/calculations/scaleConversion/scaleConversion'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Count, Label, PathTool, PolygonTool, RadiusLine, Workspace } from '../../lib/toolBoxes/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { GeometricDrawable } from '../../slices/geometry'
import { Coordinates2D, IMUP2DDrawableLocation, ItemScale, ITool, MouseButtonCodes } from '../../types'

type CreatePathItemFromTypeYield =
    | CallEffect
    | CallEffect<
          | string
          | paper.Color
          | paper.Path
          | void
          | paper.Raster
          | paper.Group
          | ITool
          | PaperManager
          | null
          | Coordinates2D
      >
    | PutEffect
    | SelectEffect

type CreatePathItemFromTypeNext = string &
    paper.Color &
    (paper.Raster | null) &
    paper.Path &
    paper.Group &
    ITool &
    PaperManager &
    GeometricDrawable &
    CreatePathTools &
    ActiveFloor

export type CreatePathTools = [PathTool, Count, PolygonTool, RadiusLine, Workspace, Label]

/*
the creation of full strategy  usually require
    - createPathItemFromType
    - addMetadataToPath
    - addSelectFunctionality...
    - addLabelJoistAndBeamsToPath
*/
export default function* createPathItemFromType(
    location: IMUP2DDrawableLocation | AISuggestion,
    coordinates: Coordinate[],
    areaOpacity: number,
    lineOpacity: number,
    shapeColor: paper.Color,
    regionPaths: paper.Path[]
): Generator<CreatePathItemFromTypeYield, paper.Path | null, CreatePathItemFromTypeNext> {
    const shapeType = (location as IMUP2DDrawableLocation).shapeType || (location as AISuggestion).shape_type

    const { settings } = location

    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return null

    const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

    const dpi: number | null = activeFloor.document_chunk.dpi || null
    const xCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_x || 1
    const yCalibrationFactor: number = activeFloor.document_chunk.calibration_factor_y || 1
    const pdfScale: number = activeFloor.document_chunk.pdf_scale || 1

    const [pathTool, countTool, polygonTool, radiusLineTool, workspaceTool]: CreatePathTools = yield call(
        manager.getTools,
        [PathTool.NAME, Count.NAME, PolygonTool.NAME, RadiusLine.NAME, Workspace.NAME]
    )

    // create a paper.Path based on the drawing type - point, area, or section/default
    let path: paper.Path

    const scaleFactor = getScaleFactor(coordinates, workspaceTool, activeFloor, regionPaths)
    const currentScaleFactor = convertScaleFactorLabelEnumToDecimal(scaleFactor)

    switch (shapeType) {
        case DRAWING_TYPES.POINT:
            const [center] = coordinates

            path = yield call(countTool.createPoint, center)

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break
        case DRAWING_TYPES.AREA:
            path = yield call(polygonTool.createPolygon, coordinates)

            if (!isEmpty((location as IMUP2DDrawableLocation).cutouts)) {
                path = yield call(cutoutAreas, path, (location as IMUP2DDrawableLocation).cutouts, polygonTool)
            }

            shapeColor.alpha = areaOpacity

            path.strokeColor = shapeColor
            path.fillColor = shapeColor
            path.strokeWidth = 0 // hide since shape is filled
            break
        case DRAWING_TYPES.RADIUS:
            // Radius Line coordinates are stored in the following order: start, center, end
            const startPoint = coordinates[0]
            const centerPoint = coordinates[1]
            const endPoint = coordinates[2]

            path = yield call(radiusLineTool.createArc, startPoint, centerPoint, endPoint)
            shapeColor.alpha = lineOpacity

            const radiusRaster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let radiusStrokeWidthScale = 100

            if (radiusRaster) {
                radiusStrokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(radiusRaster)
            }

            path.strokeColor = shapeColor
            path.strokeWidth = radiusStrokeWidthScale * ItemScale.LINE
            break

        case DRAWING_TYPES.SECTION:
        default:
            path = yield call(pathTool.createPath, coordinates)

            shapeColor.alpha = lineOpacity

            const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
            let pathStrokeWidth = 100 * ItemScale.LINE

            if (
                (location as IMUP2DDrawableLocation).drawing_type === DRAWABLE_TYPES.WALL &&
                settings?.size?.toLowerCase()?.includes('x')
            ) {
                const wallStrokeWidth = Number(settings?.size?.toLowerCase().split('x')[1])
                const [pxPerFootX, pxPerFootY] = calculatePXPerFootXandY({
                    dpi,
                    pdfScale,
                    scaleFactor: currentScaleFactor,
                    xCalibrationFactor,
                    yCalibrationFactor,
                })

                const pxPerFoot = (pxPerFootX + pxPerFootY) / 2

                pathStrokeWidth = (pxPerFoot / 12) * wallStrokeWidth
            } else if (raster) {
                // Scale the lines based on the size of the image we are working with
                const strokeWidthBasedOnRaster = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)

                pathStrokeWidth = strokeWidthBasedOnRaster * ItemScale.LINE
            }

            path.strokeColor = shapeColor
            path.strokeWidth = pathStrokeWidth
            break
    }

    return path
}

export const getScaleFactor = (
    coordinates: Coordinate[],
    workspaceTool: Workspace,
    activeFloor: ActiveFloor | null,
    regionPaths: paper.Path[]
): string => {
    // Determine if this drawable is inside a region so we can scale it appropriately
    let thisRegionGroup: paper.Path | null = null

    for (const regionPath of regionPaths) {
        for (const c of coordinates) {
            if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                break
            }
            thisRegionGroup = regionPath
        }
    }

    const scaleFactor = thisRegionGroup?.data?.scale
        ? thisRegionGroup.data.scale
        : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR

    return scaleFactor
}

interface IDrawablePathLabel {
    drawing_type: DRAWABLE_TYPES
    settings: Record<string, any>
    additionalData: IndexableObject
}

// should be called only after addMetadataToPath
export function* addLabelJoistAndBeamsToPath(
    path: paper.Path,
    drawableObjectData: IDrawablePathLabel
): Generator<CreatePathItemFromTypeYield, void, CreatePathItemFromTypeNext> {
    const { drawing_type, settings, additionalData } = drawableObjectData
    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return

    const [workspaceTool, labelTool]: [Workspace, Label] = yield call(manager.getTools, [Workspace.NAME, Label.NAME])

    // hide joist drawing types and add labels
    if (isNonAreaJoistLine(path)) {
        const group: paper.Group = yield call(labelTool.insertLabel, path, String(additionalData.joist_length) ?? '')

        group.data = path.data
        path.data = null
        // Make sure that the joist line path is not clickable
        path.locked = true

        group.visible = false

        // On initial render of a joist line
        // check whether it is a double joist
        // and if it is then render its label correctly
        updateJoistLabelGroupStyle(group, additionalData, workspaceTool)

        // On each double joist label group add an on Click
        // event that will toggle whether it is
        // a double joist
        group.children[Label.LABEL_RECTANGLE_GROUP_CHILD_INDEX].onClick = (event: paper.MouseEvent) => {
            if (event['event']['button'] === MouseButtonCodes.Left) {
                store.dispatch(
                    updateDoubleJoistAction({
                        id: group.data.opening_location_id,
                        additionalData: group.data.additionalData,
                    })
                )
            }
        }
    }

    // Add length to headers
    if (
        isHeaderAndBeamGroup(drawing_type, settings.type) &&
        !isUndefined(settings.linear_total) &&
        !isNull(settings.linear_total)
    ) {
        const { feet, inches } = convertAbsoluteDistanceToFeetAndInches(settings.quantity)

        const group: paper.Group = yield call(labelTool.insertLabel, path, `${feet}' ${inches}''`)

        group.visible = true
        group.locked = false
        group.data = path.data
    }
}
