import isEqual from 'lodash/isEqual'
import { all, call, CallEffect, put, select } from 'redux-saga/effects'

import { calculateNewDrawableLocations } from './calculateDrawableLocationsFromNewGroups'
import drawShapeByType from './drawShapeByType'
import { RedrawShapeStoreState, selectRedrawState } from './handleUpdateOpeningGroupsSuccess'
import { setActiveDrawableGroup, updateJoistLinesSuccess } from '../../../actions/drawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import addTooltipsToPath from '../../lib/utils/functionality-bindings/addTooltipsToPath'
import { setDrawableLocations } from '../../slices/2D'
import { updateToolbarMessage } from '../../slices/common'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { IMUP2DDrawableLocation } from '../../types'

export function* redrawJoistLinesOnUpdate(action: ReturnType<typeof updateJoistLinesSuccess>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const newJoistGroup = action.payload.res

        const [workspaceTool] = yield call(manager.getTools, [Workspace.NAME])

        const { areaOpacity, lineOpacity }: RedrawShapeStoreState = yield select(selectRedrawState)

        const currentDrawableGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

        const {
            additionalDrawableLocations,
            newDrawableLocations,
        }: { additionalDrawableLocations: IMUP2DDrawableLocation[]; newDrawableLocations: IMUP2DDrawableLocation[] } =
            yield call(calculateNewDrawableLocations, [newJoistGroup])

        const currentJoists: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data: any) => {
            // Do not select the joist area
            return data.opening_group_id === newJoistGroup.id && data.shapeType !== DRAWING_TYPES.AREA
        })

        // Remove the current joists from the canvas -- will be replaced below
        yield all(currentJoists.map((joist) => call([joist, 'remove'])))

        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)

        const regionPaths: paper.Path[] = yield call(workspaceTool.getAllRegionItems)

        const paths: paper.Path[] = yield all(
            additionalDrawableLocations.reduce((paths, location) => {
                // Ignore the joist area which is not deleted and redrawn
                if (
                    isEqual(location.document_chunk_id, activeFloor.document_chunk.id) &&
                    location.shapeType !== DRAWING_TYPES.AREA
                ) {
                    return [...paths, call(drawShapeByType, location, areaOpacity, lineOpacity, regionPaths)]
                }

                return paths
            }, [] as CallEffect<paper.Path | null>[])
        )

        yield all(paths.map((path) => call(addTooltipsToPath, path, workspaceTool)))

        // we need set active drawable group from new joist to display joist lines
        if (currentDrawableGroup) yield put(setActiveDrawableGroup(newJoistGroup))

        // We've updated the joist's drawable locations -- we need to save them in the store's 2D slice.
        yield put(setDrawableLocations(newDrawableLocations))
    } catch (error) {
        yield call(console.error, (error as any).message)
    } finally {
        yield put(updateToolbarMessage(null))
    }
}
