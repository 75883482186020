import { Form } from 'react-bootstrap'

export const thicknessFieldName = 'thickness'
export const widthFieldName = 'width'
export const lengthFieldName = 'length'

import './lumber-dimensions-select-dropdown.scss'

export interface ILumberDimensionsSelectDropdownProps {
    fieldsData: {
        [thicknessFieldName]?: string
        [widthFieldName]?: string
        [lengthFieldName]?: string
    }
    selectionValues: {
        [thicknessFieldName]: {
            enumNames: string[]
            enum: string[]
        }
        [widthFieldName]: {
            enumNames: string[]
            enum: string[]
        }
        [lengthFieldName]: {
            enumNames: string[]
            enum: string[]
        }
    }
    onFieldChange: (value: string, fieldName: string) => void
    shouldIncludeThickness?: boolean
    shouldIncludeWidth?: boolean
    shouldIncludeLength: boolean
    isLengthFieldDisabled?: boolean
    placeholders?: {
        thickness: string
        width: string
        length: string
    }
}

export function LumberDimensionsSelectDropDown({
    fieldsData,
    onFieldChange,
    selectionValues,
    shouldIncludeThickness = true,
    shouldIncludeWidth = true,
    shouldIncludeLength,
    isLengthFieldDisabled = true,
    placeholders = {
        thickness: 'T',
        width: 'W',
        length: 'L',
    },
}: ILumberDimensionsSelectDropdownProps) {
    return (
        <div id="LumberDimensionFieldsWrapper">
            <div className="lumber-dimensions-field">
                <div className="fields-wrapper">
                    {shouldIncludeThickness && (
                        <div className="dimension-field-wrapper thickness" title="Lumber thickness in inches">
                            <Form.Control
                                as="select"
                                className="dimension-field"
                                onChange={(e) => onFieldChange(e.target.value, thicknessFieldName)}
                                value={fieldsData[thicknessFieldName]}
                            >
                                <option value="">{placeholders.thickness}</option>
                                {selectionValues[thicknessFieldName].enumNames?.map((option, index) => (
                                    <option
                                        key={option}
                                        value={selectionValues[thicknessFieldName].enum?.[index] as string}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    )}

                    {shouldIncludeThickness && shouldIncludeWidth && <span className="divider">X</span>}

                    {shouldIncludeWidth && (
                        <div className="dimension-field-wrapper width" title="Lumber width in inches">
                            <Form.Control
                                as="select"
                                className="dimension-field"
                                onChange={(e) => onFieldChange(e.target.value, widthFieldName)}
                                value={fieldsData[widthFieldName]}
                            >
                                <option value="">{placeholders.width}</option>
                                {selectionValues[widthFieldName].enumNames?.map((option, index) => (
                                    <option
                                        key={option}
                                        value={selectionValues[widthFieldName].enum?.[index] as string}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    )}

                    {shouldIncludeWidth && shouldIncludeLength && <span className="divider">X</span>}

                    {shouldIncludeLength && (
                        <div className="dimension-field-wrapper length" title="Lumber length in feet">
                            <Form.Control
                                as="select"
                                className="dimension-field"
                                disabled={isLengthFieldDisabled}
                                onChange={(e) => onFieldChange(e.target.value, lengthFieldName)}
                                value={fieldsData[lengthFieldName]}
                            >
                                <option value="">{placeholders.length}</option>
                                {selectionValues[lengthFieldName].enumNames?.map((option, index) => (
                                    <option
                                        key={option}
                                        value={selectionValues[lengthFieldName].enum?.[index] as string}
                                    >
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
