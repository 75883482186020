import { PolygonTool } from '../polygon/Polygon.tool'
import { IMasterSetPlanToCreate, MASTER_SET_PLAN_ENUMS } from '../../../../../../models/masterSetPlan'
import { MASTER_SET_PLAN_COLOR } from '../../../../../../shared/constants/colors'
import store from '../../../../../../stores'
import { setMasterSetPlanToCreate } from '../../../../../slices/masterSetPlan'
import { initialToolsState, ToolsState } from '../../../../../slices/tools'
import { Coordinates2D, Cursors, ItemScale, PaperToolConfig } from '../../../../../types'
import addSelectFunctionalityToMasterSetPlanOption from '../../../../utils/functionality-bindings/addSelectFunctionalityToMasterSetPlanOption'

/**
 * MasterSetPlanRegion.tool.tsx
 * Creates a corner to corner master set plan region upon mouse drag
 */
export class MasterSetPlanRegion extends PolygonTool {
    static NAME = 'MASTER_SET_PLAN_REGION'
    static CURSOR = Cursors.CROSSHAIR

    // Define constants for the label offset values
    private static readonly LABEL_OFFSET_X = 8
    private static readonly LABEL_OFFSET_Y = 80

    private labelTextOffsetPoint: paper.Point = new this.paper.Point(
        MasterSetPlanRegion.LABEL_OFFSET_X,
        MasterSetPlanRegion.LABEL_OFFSET_Y
    )
    private readonly labelTextColor: paper.Color = new this.paper.Color('white')

    private temporaryRectanglePath: paper.Path | null = null

    private dashArray: ToolsState['dashArray'] = initialToolsState.dashArray
    private opacity: number = initialToolsState.areaOpacityValue
    private readonly strokeWidthMultiplier = 1.2

    private colorOption: paper.Color = new this.paper.Color(MASTER_SET_PLAN_COLOR.OPTION)
    private colorBaseHome: paper.Color = new this.paper.Color(MASTER_SET_PLAN_COLOR.BASE_HOME)

    private defaultMasterSetPlanOptionId = 0

    private startPoint: paper.Point | null = null
    private endPoint: paper.Point | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = MasterSetPlanRegion.NAME
    }

    public updatePathColorAndText(
        pathItem: paper.Item,
        backgroundItem: paper.Item,
        labelTextItem: paper.PointText,
        optionType: MASTER_SET_PLAN_ENUMS
    ) {
        pathItem.strokeColor =
            optionType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME ? this.colorBaseHome : this.colorOption
        labelTextItem.content = optionType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME ? 'Base' : 'Option'
        backgroundItem.fillColor = pathItem.strokeColor
    }

    /**
     * function to build the styles of the master set plan regions with labels.
     */
    private buildMasterSetPlanRegionProperties = (
        path: paper.Path,
        masterSetPlanId: number,
        shapeType: MASTER_SET_PLAN_ENUMS
    ): paper.Path => {
        path.strokeColor =
            shapeType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME ? this.colorBaseHome : this.colorOption
        path.opacity = this.dashArray.length ? 1 : this.opacity

        path.strokeWidth = this.strokeWidth || initialToolsState.strokeWidth // if the stroke is not set, then set it to a default value
        path.strokeWidth *= ItemScale.LINE * this.strokeWidthMultiplier
        path.fillColor = null
        path.data.selectable = true

        if (shapeType === MASTER_SET_PLAN_ENUMS.TEMPORARY_MASTER_SET_PLAN_REGION) {
            // these are multiplied to scale the dashes based on the stroke width (the default numbers in the store are based on 1px width)
            path.dashArray = [this.dashArray[0] * path.strokeWidth, this.dashArray[1] * path.strokeWidth]
        }

        if (shapeType !== MASTER_SET_PLAN_ENUMS.TEMPORARY_MASTER_SET_PLAN_REGION) {
            // Create a text label
            const temporaryTextLabel = new this.paper.PointText(path.bounds.topLeft.add(this.labelTextOffsetPoint))

            temporaryTextLabel.fillColor = this.labelTextColor
            temporaryTextLabel.justification = 'left'
            temporaryTextLabel.fontSize = `${Math.round(path.strokeWidth) * 10}px`
            temporaryTextLabel.content =
                shapeType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME ? 'Base' : 'Option'

            // Create a background rectangle (bar) behind the text
            const labelTextBarPadding = 10 // Add some padding around the text
            const textBounds = temporaryTextLabel.bounds

            const backgroundBar = new this.paper.Path.Rectangle({
                point: [textBounds.topLeft.x - labelTextBarPadding, textBounds.topLeft.y - labelTextBarPadding], // Position the bar behind the text
                size: [textBounds.width + 2 * labelTextBarPadding, textBounds.height + 2 * labelTextBarPadding], // Make the bar slightly larger than the text
                fillColor: path.strokeColor,
                strokeWidth: 0, // No border
                radius: new this.paper.Size(10, 10),
            })
            // Make sure the background bar is rendered behind the text

            backgroundBar.insertBelow(temporaryTextLabel)

            // Store label and background bar IDs in path data
            path.data.labelId = temporaryTextLabel.id
            path.data.labelBackgroundId = backgroundBar.id
        }

        path.data.shapeType = shapeType
        path.data.selectable = false
        path.data.masterSetPlanId = masterSetPlanId

        // add select deselect and delete functionality
        addSelectFunctionalityToMasterSetPlanOption(path)

        return path // The group will contain the path at index 0 and the label at index 1
    }

    renderBaseHomeOptionPageRegion = (
        coords: Coordinates2D,
        masterSetPlanId: number,
        isOption: boolean
    ): paper.Path => {
        const path = this.createPolygon(coords)
        const shapeType = isOption
            ? MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_OPTION
            : MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_BASE_HOME

        return this.buildMasterSetPlanRegionProperties(path, masterSetPlanId, shapeType)
    }

    onMouseMove = (event: paper.MouseEvent): void => {
        if (this.startPoint && !this.endPoint) {
            if (this.temporaryRectanglePath) {
                const newEndPoint = event.point

                this.updateRectangleWithPoints(this.temporaryRectanglePath, this.startPoint, newEndPoint)
            }
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        if (this.startPoint && this.endPoint) {
            // restart drawing again
            this.cancel()
            this.temporaryRectanglePath = null
        }

        if (!this.startPoint) {
            this.startPoint = event.point

            this.temporaryRectanglePath = new this.paper.Path.Rectangle(this.startPoint, this.startPoint)
            this.buildMasterSetPlanRegionProperties(
                this.temporaryRectanglePath,
                this.defaultMasterSetPlanOptionId,
                MASTER_SET_PLAN_ENUMS.TEMPORARY_MASTER_SET_PLAN_REGION
            )
            store.dispatch(setMasterSetPlanToCreate([]))
        } else {
            this.endPoint = event.point
            if (this.temporaryRectanglePath?.id) {
                const newMasterSetPlanToCreate: IMasterSetPlanToCreate = {
                    id: this.temporaryRectanglePath.id,
                    coordinates: this.temporaryRectanglePath.segments.map((segment) => [
                        segment.point.x,
                        segment.point.y,
                    ]) as Coordinates2D,
                }

                store.dispatch(setMasterSetPlanToCreate([newMasterSetPlanToCreate]))
            }
        }
    }

    /**
     * On mouse drag if it is right click
     * or middle click then pan
     */
    onMouseDrag = (event) => {
        this.toolPanning(event)
    }

    onMouseUp = () => {
        this.setState('common', { cursor: MasterSetPlanRegion.CURSOR })
    }

    // Function to update the rectangle's shape using start and end points
    updateRectangleWithPoints = (rectanglePath: paper.Path, newStartPoint: paper.Point, newEndPoint: paper.Point) => {
        // calculate the four corners from the startPoint and newEndPoint
        const topLeft = newStartPoint
        const topRight = new this.paper.Point(newEndPoint.x, newStartPoint.y)
        const bottomRight = newEndPoint
        const bottomLeft = new this.paper.Point(newStartPoint.x, newEndPoint.y)

        // Update the segments of the rectangle
        rectanglePath.segments = [
            new this.paper.Segment(topLeft),
            new this.paper.Segment(topRight),
            new this.paper.Segment(bottomRight),
            new this.paper.Segment(bottomLeft),
        ]

        rectanglePath.closed = true // Ensure the path is closed
    }

    handleDeleteMasterSetPlanPath = (path: paper.Path) => {
        // Remove the label and background bar attached to the master set plan if they exist
        if (path.data.labelId) {
            const label = this.paper.project.getItem({ id: path.data.labelId })

            if (label) {
                label.remove()
            }
        }

        if (path.data.labelBackgroundId) {
            const backgroundBar = this.paper.project.getItem({ id: path.data.labelBackgroundId })

            if (backgroundBar) {
                backgroundBar.remove()
            }
        }

        path.remove()
    }

    cancel = (): void => {
        this.startPoint = null
        this.endPoint = null
        if (this.temporaryRectanglePath) {
            // Clear the previous segments of the temporary path
            this.handleDeleteMasterSetPlanPath(this.temporaryRectanglePath)
        }
    }
}

export default MasterSetPlanRegion
