import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'

import { BasicFloorMapping } from './mappings'
import { FETCH_MAPPING_SUCCESS, SET_ACTIVE_FLOOR } from '../../actions/drawable'
import { ActiveFloor } from '../../models/activeFloor'
import { DocumentChunk } from '../../models/documentChunk'
import { DocumentMapping } from '../../models/documentMapping'
import { Project } from '../../models/project'
import { ProjectDocument } from '../../models/projectDocument'
import { Region } from '../../models/region'
import { DEFAULT_SCALE_FACTOR } from '../../shared/constants/scales'
import { RootState } from '../../stores'

export const gotMappings = createAction<IMappings>(FETCH_MAPPING_SUCCESS)
export const setActiveDocument = createAction<ActiveFloor>(SET_ACTIVE_FLOOR)

export interface IMappings {
    floors: ActiveFloor[]
    project: Project
}

export interface DocumentChunkWBuildingID extends DocumentChunk {
    buildingID: number | null
}
export interface NormalizedDocumentChunk extends Omit<DocumentChunk, 'regions' | 'project_document'> {
    mappingID: number | null
    buildingID: number | null
}

export type DocumentsState = {
    documentChunks: NormalizedDocumentChunk[] | null
    projectDocuments: ProjectDocument[] | null
    projectDocumentMappings: DocumentMapping[] | null
    activeDocumentChunkId: number | null
    activeFloorDocId: number | null
}

export const initialDocumentsState: DocumentsState = {
    documentChunks: null,
    projectDocuments: null,
    projectDocumentMappings: null,
    activeDocumentChunkId: null,
    activeFloorDocId: null,
}

function extractDocumentChunksFromFloors(state: DocumentsState, floors: ActiveFloor[]): void {
    const normalizedChunks: NormalizedDocumentChunk[] = floors.map((floor: ActiveFloor) => {
        return {
            ...floor.document_chunk,
            mappingID: floor.id,
            buildingID: floor.building_id || null,
            regions: undefined,
            project_document: undefined,
        }
    })

    state.documentChunks = normalizedChunks
}

function extractProjectDocumentsFromFloors(state: DocumentsState, floors: ActiveFloor[]): void {
    const projectDocIds: number[] = []
    const projectDocs: ProjectDocument[] = []

    floors.reduce((uniqueProjDocs, currentFloor) => {
        if (!projectDocIds.includes(currentFloor.project_document.id)) {
            projectDocs.push({ ...currentFloor.project_document })
            projectDocIds.push(currentFloor.project_document.id)
        }

        return uniqueProjDocs
    }, projectDocs)
    state.projectDocuments = projectDocs
}

function processMappings(
    state: DocumentsState,
    action: PayloadAction<{ floors: ActiveFloor[]; project: Project }>
): void {
    extractProjectDocumentsFromFloors(state, action.payload.floors)
    extractDocumentChunksFromFloors(state, action.payload.floors)
}

function handleSetActiveDocument(state: DocumentsState, { payload }: PayloadAction<ActiveFloor>) {
    state.activeFloorDocId = payload.document_chunk.id
}

const extraReducers = (builder: ActionReducerMapBuilder<DocumentsState>): void => {
    builder.addCase(gotMappings, processMappings)
    builder.addCase(setActiveDocument, handleSetActiveDocument)
}

const documentsSlice = createSlice({
    name: 'documents',
    initialState: initialDocumentsState,
    reducers: {
        initializeDocumentChunks(state: DocumentsState, { payload }: PayloadAction<DocumentChunk[]>): void {
            state.documentChunks = payload.map((c) => ({
                ...c,
                mappingID: null,
                project_document: undefined,
                regions: undefined,
                buildingID:
                    state.projectDocumentMappings?.find((mapping) => mapping.document_chunk_id === c.id)?.building_id ??
                    null,
            }))
        },
        initializeProjectDocumentsFromChunks(state: DocumentsState, { payload }: PayloadAction<DocumentChunk[]>): void {
            state.projectDocuments = payload.map((docChunk) => {
                return { ...docChunk.project_document, mappingID: null }
            })
        },
        moveChunksToBuilding(
            state,
            {
                payload: { updatedDocumentChunks, toBuildingId },
            }: PayloadAction<{ toBuildingId: number; updatedDocumentChunks: DocumentChunk[] }>
        ) {
            const documentChunks = state.documentChunks

            if (!documentChunks) return

            const updatedDocumentChunkIdsSet: Set<number> = new Set(updatedDocumentChunks.map((dc) => dc.id))

            const newChunks = documentChunks.map((normChunk) => {
                if (updatedDocumentChunkIdsSet.has(normChunk.id)) {
                    return { ...normChunk, buildingID: toBuildingId }
                }

                return normChunk
            })

            state.documentChunks = [...newChunks]

            const mappings = state.projectDocumentMappings

            if (!mappings) return

            const newMappings = mappings.map((projMapping) => {
                if (updatedDocumentChunkIdsSet.has(projMapping.document_chunk_id)) {
                    return { ...projMapping, building_id: toBuildingId }
                }

                return projMapping
            })

            state.projectDocumentMappings = [...newMappings]
        },
        hardUpdateDocumentMappings(state: DocumentsState, { payload }: PayloadAction<DocumentMapping[]>) {
            state.projectDocumentMappings = payload
        },
        updateDocumentMappings(state: DocumentsState, { payload }: PayloadAction<DocumentMapping[]>) {
            const newDocumentMappingIds = payload.map((mapping) => mapping.id)
            const currentDocumentMappingIds = state.projectDocumentMappings?.map((mapping) => mapping.id) ?? []

            const newMappings: DocumentMapping[] = []

            for (const mapping of state.projectDocumentMappings ?? []) {
                if (newDocumentMappingIds.includes(mapping.id)) {
                    const newMapping = payload.find((m) => m.id === mapping.id)

                    newMapping && newMappings.push(newMapping)

                    // Update the building id for the chunk
                    const chunk = state.documentChunks?.find((chunk) => mapping.document_chunk_id === chunk.id)

                    if (chunk && newMapping) {
                        chunk.buildingID = newMapping?.building_id
                        chunk.mappingID = newMapping.id
                    }
                } else {
                    newMappings.push(mapping)
                }
            }

            let updatedProjectDocumentMappings = [
                // Append any document mappings that aren't already in the store
                ...newMappings,
                ...payload.filter((m) => !currentDocumentMappingIds.includes(m.id)),
            ]

            const areMappingsWithDefaultScaleFactor =
                state.projectDocumentMappings &&
                state.projectDocumentMappings?.every((m) => m.scale_factor === DEFAULT_SCALE_FACTOR)

            if (payload.length === 1 && areMappingsWithDefaultScaleFactor) {
                updatedProjectDocumentMappings = updatedProjectDocumentMappings.map((m) => ({
                    ...m,
                    scale_factor: payload[0].scale_factor,
                }))
            }

            state.projectDocumentMappings = updatedProjectDocumentMappings
        },
        resetDocumentState(state: DocumentsState) {
            state.documentChunks = initialDocumentsState.documentChunks
            state.projectDocuments = initialDocumentsState.projectDocuments
            state.projectDocumentMappings = initialDocumentsState.projectDocumentMappings
        },
        updateActiveChunk(state: DocumentsState, { payload }: PayloadAction<DocumentChunk | null>) {
            state.activeDocumentChunkId = !isNull(payload) ? payload.id : null
        },
        updateSingleDocumentChunk(state: DocumentsState, { payload }: PayloadAction<DocumentChunk>) {
            if (!state.documentChunks) return

            state.documentChunks = state.documentChunks.map((c) => {
                if (c.id === payload.id) {
                    const newChunk: NormalizedDocumentChunk = {
                        ...payload, // Update the new properties of the chunk from the payload
                        // Inherit the properties of the old normalized chunk
                        mappingID: c.mappingID,
                        buildingID: c.buildingID,
                    }

                    // Normalize the chunk
                    delete newChunk['project_document']
                    delete newChunk['regions']

                    return newChunk
                }

                return c
            })
        },
        deleteDocumentChunks(
            state,
            { payload: { documentChunksIdsToDelete } }: PayloadAction<{ documentChunksIdsToDelete: number[] }>
        ) {
            const documentChunks = state.documentChunks

            if (!documentChunks) return

            const newChunks = documentChunks.map((normChunk) => {
                // deleted document chunk is marked with buildingID -2 and should have is_user_deleted as true to keep
                // in correct section
                if (documentChunksIdsToDelete.includes(normChunk.id)) {
                    return { ...normChunk, buildingID: -2, is_user_deleted: true }
                }

                return normChunk
            })

            state.documentChunks = [...newChunks]

            const mappings = state.projectDocumentMappings

            if (!mappings) return

            const newMappings = mappings.filter(
                (projMapping) => !documentChunksIdsToDelete.includes(projMapping.document_chunk_id)
            )

            state.projectDocumentMappings = [...newMappings]
        },
        restoreDocumentChunks(
            state,
            { payload: { documentChunksIdsToRestore } }: PayloadAction<{ documentChunksIdsToRestore: number[] }>
        ) {
            const documentChunks = state.documentChunks

            if (!documentChunks) return

            const newChunks = documentChunks.map((normChunk) => {
                // in case that document chunks are in array of chunksIds to restore, set buildingID -1 to move to unclassified
                // section, and mark is_user_deleted to false, because more options when card is in unclassified section is delete
                if (documentChunksIdsToRestore.includes(normChunk.id)) {
                    return { ...normChunk, buildingID: -1, is_user_deleted: false }
                }

                return normChunk
            })

            state.documentChunks = [...newChunks]

            const mappings = state.projectDocumentMappings

            if (!mappings) return

            const newMappings = mappings.map((projMapping) => {
                if (documentChunksIdsToRestore.includes(projMapping.document_chunk_id)) {
                    return { ...projMapping, building_id: -1 }
                }

                return projMapping
            })

            state.projectDocumentMappings = [...newMappings]
        },
        resetDocumentChunkCalibration(
            state,
            { payload: { documentChunkId } }: PayloadAction<{ documentChunkId: number }>
        ) {
            if (state.documentChunks) {
                state.documentChunks = state.documentChunks.map((documentChunk) => {
                    if (documentChunk.id === documentChunkId) {
                        return {
                            ...documentChunk,
                            calibration_factor_x: 1,
                            calibration_factor_y: 1,
                        }
                    }

                    return documentChunk
                })
            }
        },
        setDocumentChunks(
            state,
            { payload: { documentChunks } }: PayloadAction<{ documentChunks: NormalizedDocumentChunk[] }>
        ) {
            state.documentChunks = documentChunks
        },
    },
    extraReducers,
})

export default documentsSlice
export const {
    initializeDocumentChunks,
    updateActiveChunk,
    initializeProjectDocumentsFromChunks,
    moveChunksToBuilding,
    hardUpdateDocumentMappings,
    updateDocumentMappings,
    resetDocumentState,
    updateSingleDocumentChunk,
    deleteDocumentChunks,
    restoreDocumentChunks,
    resetDocumentChunkCalibration,
    setDocumentChunks,
} = documentsSlice.actions

function rebuildActiveFloor({
    documentChunks,
    documentMappings,
    chunkId,
    floorMappings,
    aiClassification,
    activeFloorId,
    projectDocuments,
    regions,
}: {
    documentMappings: DocumentMapping[]
    projectDocuments: ProjectDocument[]
    documentChunks: NormalizedDocumentChunk[]
    floorMappings: BasicFloorMapping[]
    chunkId: number
    aiClassification: {
        id: number
        ai_classification_data: string
    } | null
    activeFloorId: number
    regions: Region[]
}): ActiveFloor | null {
    const chunk = documentChunks.find((chunk) => chunk.id === chunkId)

    const documentMapping = documentMappings.find((mapping) => mapping.document_chunk_id === chunkId)

    const activeFloorMapping = documentMappings.find((mapping) => mapping.id === activeFloorId)

    if (!documentMapping || !chunk || !activeFloorMapping) return null

    const floorMapping = floorMappings.find((mapping) => mapping.hash === documentMapping.floor_hash)

    const documentChunk = rebuildChunkFromProjectDocumentsAndNormalizedChunk(projectDocuments, chunk, regions)

    if (!documentChunk || !floorMapping) return null

    return {
        ...floorMapping,
        ...documentMapping,
        page_name: documentMapping.page_name || '',
        scale_factor: activeFloorMapping.scale_factor,
        project_document: documentChunk.project_document,
        ai_classification: aiClassification,
        document_chunk: documentChunk,
    }
}

// Selector utility functions
const rebuildChunkFromProjectDocumentsAndNormalizedChunk = (
    projectDocs: ProjectDocument[],
    normDocChunk: NormalizedDocumentChunk,
    regions: Region[]
): DocumentChunk | null => {
    const projDocument = projectDocs.find((projD) => projD.id === normDocChunk.project_document_id)

    if (!projDocument) return null

    return {
        id: normDocChunk.id,
        page: normDocChunk.page,
        src: normDocChunk.src,
        pdf_scale: normDocChunk.pdf_scale,
        dpi: normDocChunk.dpi,
        calibration_factor_x: normDocChunk.calibration_factor_x,
        calibration_factor_y: normDocChunk.calibration_factor_y,
        project_document_id: normDocChunk.project_document_id,
        project_document: projDocument,
        regions: regions.filter((region) => region.document_chunk_id === normDocChunk.id),
        is_user_deleted: normDocChunk.is_user_deleted,
        updated_at: normDocChunk.updated_at,
    }
}

// Straightforward selectors

export const selectNormalizedDocumentChunks = createSelector(
    ({ IMUP: { documents } }: RootState) => documents.documentChunks,
    (state) => state
)

export const selectDocumentMappings = createSelector(
    ({ IMUP: { documents } }: RootState) => documents.projectDocumentMappings,
    (state) => state
)

export const selectActiveDocumentMapping = createSelector(
    ({ IMUP: { documents } }: RootState) =>
        documents.projectDocumentMappings?.find((m) => m.document_chunk_id === documents.activeDocumentChunkId) ?? null,
    (state) => state
)

// Smart selectors

export const selectActiveDocumentChunk: (state: RootState) => DocumentChunk | null = createSelector(
    ({ IMUP: { documents, region } }: RootState) => {
        const projDocuments = documents.projectDocuments
        const allDocChunks = documents.documentChunks
        const activeChunkId = documents.activeDocumentChunkId
        const regions = region.regions

        return { projDocuments, allDocChunks, activeChunkId, regions }
    },
    (state) => {
        const { projDocuments, allDocChunks, activeChunkId, regions } = state

        if (isNull(projDocuments) || isNull(allDocChunks) || isNull(activeChunkId)) return null

        const activeChunk: NormalizedDocumentChunk | undefined = allDocChunks.find(
            (chunk) => chunk.id === activeChunkId
        )

        if (!activeChunk) return null

        return rebuildChunkFromProjectDocumentsAndNormalizedChunk(projDocuments, activeChunk, regions)
    }
)

export const selectHydratedChunkFromActiveFloor: (state: RootState) => DocumentChunk | null = createSelector(
    ({ IMUP: { documents, region } }: RootState) => {
        const projDocuments = documents.projectDocuments
        const allDocChunks = documents.documentChunks
        const activeChunkId = documents.activeDocumentChunkId
        const regions = region.regions

        return { projDocuments, allDocChunks, activeChunkId, regions }
    },
    (state) => {
        const { projDocuments, allDocChunks, activeChunkId, regions } = state

        if (isNull(projDocuments) || isNull(allDocChunks) || isNull(activeChunkId)) return null

        const activeChunk: NormalizedDocumentChunk | undefined = allDocChunks.find(
            (chunk) => chunk.id === activeChunkId
        )

        if (!activeChunk) return null

        return rebuildChunkFromProjectDocumentsAndNormalizedChunk(projDocuments, activeChunk, regions)
    }
)

export const selectDocumentChunks = createSelector(
    [
        (state: RootState) => state.IMUP.documents.projectDocuments,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.region.regions,
    ],
    (projDocuments, documentChunks, regions) => {
        if (!projDocuments || projDocuments.length === 0 || !documentChunks) return null
        const resultantChunks = documentChunks.reduce((chunks, normalChunk) => {
            const documentChunk = rebuildChunkFromProjectDocumentsAndNormalizedChunk(
                projDocuments,
                normalChunk,
                regions
            )

            if (isNull(documentChunk)) return chunks

            chunks.push(documentChunk)

            return chunks
        }, [] as DocumentChunk[])

        if (resultantChunks.some((chunk) => isNull(chunk))) return null

        return resultantChunks
    }
)

export const selectDocumentChunksWBuildingID: (state: RootState) => DocumentChunkWBuildingID[] | null = createSelector(
    [
        (state: RootState) => state.IMUP.documents.projectDocuments,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.region.regions,
    ],
    (projectDocuments, documentChunks, regions) => {
        const projDocuments = projectDocuments

        if (!projDocuments || projDocuments.length === 0 || !documentChunks) return null

        return documentChunks.reduce((chunks, normalChunk) => {
            const documentChunk = rebuildChunkFromProjectDocumentsAndNormalizedChunk(
                projDocuments,
                normalChunk,
                regions
            )

            if (!documentChunk || !isPageNamesOrSrcsNot3DMUPPages(documentChunk?.src)) return chunks

            chunks.push({
                ...documentChunk,
                buildingID: normalChunk.buildingID,
            })

            return chunks
        }, [] as DocumentChunkWBuildingID[])
    }
)

export const selectDigitizerEnabled = createSelector(
    ({ IMUP: { documents } }: RootState) =>
        // We only want to enable the digitizer if there is at least one page ready for digitizing
        !!documents.projectDocumentMappings && documents.projectDocumentMappings.length > 0,
    (state) => state
)

function determineActiveDocumentChunkId(
    activeFloorDocId: number | null,
    currentOverlayChunkId: number | null,
    currentEstimatedOptionOverlayChunkId: number | null,
    isConflictResolutionComplete: boolean
) {
    if (currentOverlayChunkId && isConflictResolutionComplete) {
        return currentOverlayChunkId
    } else if (currentEstimatedOptionOverlayChunkId && !isConflictResolutionComplete) {
        return currentEstimatedOptionOverlayChunkId
    }

    return activeFloorDocId
}

export const selectActiveFloorId = createSelector(
    ({ IMUP }: RootState) => {
        const { masterSetPlan, documents } = IMUP

        return determineActiveDocumentChunkId(
            documents.activeFloorDocId,
            IMUP['2D'].currentOverlayChunkId,
            IMUP['2D'].currentEstimatedOptionOverlayChunkId,
            masterSetPlan.isConflictResolutionComplete
        )
    },
    (id) => id
)

export const selectActiveChunkScalesAndDPI = createSelector(
    ({ IMUP: { documents } }: RootState) => {
        return {
            activeFloorDocId: documents.activeFloorDocId,
            activeDocChunkId: documents.activeDocumentChunkId,
            documentChunks: documents.documentChunks,
        }
    },
    ({ activeFloorDocId, activeDocChunkId, documentChunks }) => {
        const activeId = activeDocChunkId ?? activeFloorDocId
        const possibleActiveChunk = documentChunks?.find((chunk) => chunk.id === activeId)

        return {
            dpi: possibleActiveChunk?.dpi ?? null,
            pdfScale: possibleActiveChunk?.pdf_scale ?? 1,
            xCalibrationFactor: possibleActiveChunk?.calibration_factor_x ?? 1,
            yCalibrationFactor: possibleActiveChunk?.calibration_factor_y ?? 1,
        }
    }
)

export const selectDocumentMappingByActiveFloorId = createSelector(
    ({ IMUP: { documents } }: RootState) => {
        return (
            documents.projectDocumentMappings?.find((m) => m.document_chunk_id === documents.activeFloorDocId) ?? null
        )
    },
    (id) => id
)

export const selectOverlaidDocumentMapping = createSelector(
    [
        (state: RootState) =>
            state.IMUP['2D'].currentOverlayChunkId || state.IMUP['2D'].currentEstimatedOptionOverlayChunkId,
        (state: RootState) => state.IMUP.documents.projectDocumentMappings,
    ],
    (overlayId, mappings) => {
        if (overlayId === null || !mappings) return null
        const potentialMapping = mappings.find((mapping) => mapping.document_chunk_id === overlayId)

        if (!potentialMapping) return null

        return potentialMapping
    }
)

export const selectDrawableActiveFloor = createSelector(
    [
        (state: RootState) => state.IMUP.documents.projectDocumentMappings,
        (state: RootState) => state.IMUP.documents.documentChunks,
        (state: RootState) => state.IMUP.mappings.allPossibleFloorMappings,
        (state: RootState) => state.IMUP.documents.projectDocuments,
        (state: RootState) => state.IMUP.region.regions,
        (state: RootState) => state.IMUP['2D'].currentOverlayChunkId,
        (state: RootState) => state.IMUP.masterSetPlan.isConflictResolutionComplete,
        (state: RootState) => state.IMUP['2D'].currentEstimatedOptionOverlayChunkId,
        (state: RootState) => state.drawable.activeFloor,
    ],
    (
        documentMappings,
        documentChunks,
        floorMappings,
        projectDocuments,
        regions,
        currentOverlayChunkId,
        isConflictResolutionComplete,
        currentEstimatedOptionOverlayChunkId,
        activeFloor
    ): ActiveFloor | null => {
        if (!documentMappings || !floorMappings || !projectDocuments || !documentChunks || !activeFloor) {
            return activeFloor
        }

        return rebuildActiveFloor({
            projectDocuments,
            documentChunks,
            documentMappings,
            floorMappings,
            regions,
            activeFloorId: activeFloor.id,
            // in the case of the overlay we want to use the scale from the page which is being overlaid
            // on not its parent page
            chunkId:
                determineActiveDocumentChunkId(
                    activeFloor.document_chunk.id,
                    currentOverlayChunkId,
                    currentEstimatedOptionOverlayChunkId,
                    isConflictResolutionComplete
                ) ?? -1,
            aiClassification: activeFloor.ai_classification,
        })
    }
)

export function isPageNamesOrSrcsNot3DMUPPages(srcOrName?: string | null) {
    const namesToNotHave = ['roofing', 'nonRoof', '3Dummy']

    return namesToNotHave.every((name) => !srcOrName?.includes(name))
}
