import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import isBoolean from 'lodash/isBoolean'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks'
import {
    selectActiveBuilding,
    selectBuildingActiveTab,
    selectBuildingSettingOptions,
    setBuildingsSelectedOptions,
} from '../../../../../../../imup/slices/buildings'
import { Building } from '../../../../../../../models/projectBuilding'
import { prepareDataQA } from '../../../../../../../utils/stringFormatters'
import { LumberDimensionsSelectDropDown } from '../../../../../../markup/utils/components/material-forms/form-fields/lumber-dimensions/lumber-dimension-select-dropdown/lumber-dimensions-select-dropdow'
import { FIELD_KEY } from '../../../../utils/constants'
import { convertValueToKey } from '../../../../utils/helpers'

interface ISwitchConditionalFieldProps {
    fieldKey: string
    label: string
    onChange: (fieldKey: string, value: boolean) => void
    defaultTWLData?: {
        thickness: string
        width: string
        length: string
    }
    defaultChecked?: boolean
    includeThickness?: boolean
    includeWidth?: boolean
    includeLength?: boolean
    showSwitch?: boolean
    isLengthFieldDisabled?: boolean
}

// default data to switches, based on switch name
const defaultDimensionValues = {
    [FIELD_KEY.GABLE_LADDER_FRAMING]: {
        thickness: '2',
        width: '6',
        length: '192',
    },
    [FIELD_KEY.SUB_FASCIA]: {
        thickness: '2',
        width: '6',
        length: '192',
    },
    [FIELD_KEY.EAVE_BLOCKING]: {
        thickness: '2',
        width: '4',
        length: '192',
    },
}

// select options for the fields
const getFieldLumberOptionKeys = {
    [FIELD_KEY.GABLE_LADDER_FRAMING]: [FIELD_KEY.LUMBER_THICKNESS, FIELD_KEY.LUMBER_WIDTH, FIELD_KEY.LUMBER_LENGTH],
    [FIELD_KEY.SUB_FASCIA]: [FIELD_KEY.LUMBER_THICKNESS, FIELD_KEY.LUMBER_WIDTH, FIELD_KEY.LUMBER_LENGTH],
    [FIELD_KEY.RAFTER_TAIL]: [FIELD_KEY.LUMBER_THICKNESS, FIELD_KEY.LUMBER_WIDTH, FIELD_KEY.LUMBER_LENGTH_RAFTER_TAIL],
    [FIELD_KEY.SOFFIT_TYPE]: [
        FIELD_KEY.LUMBER_THICKNESS_SOFFIT_TYPE,
        FIELD_KEY.LUMBER_WIDTH_SOFFIT_TYPE,
        FIELD_KEY.LUMBER_LENGTH_SOFFIT_TYPE,
    ],
    [FIELD_KEY.EAVE_BLOCKING]: [FIELD_KEY.LUMBER_THICKNESS, FIELD_KEY.LUMBER_WIDTH, FIELD_KEY.LUMBER_LENGTH],
}

export const dimensionsMaterialKey = 'dimensions_material'

const SwitchLumberDimensions = ({
    showSwitch = true,
    fieldKey,
    label,
    onChange,
    defaultTWLData,
    defaultChecked = true,
    includeThickness = true,
    includeWidth = true,
    includeLength = false,
    isLengthFieldDisabled = false,
}: ISwitchConditionalFieldProps) => {
    const dispatch = useAppDispatch()

    const activeBuilding: Building | null = useAppSelector(selectActiveBuilding)
    const buildingActiveTab: string | null = useAppSelector(selectBuildingActiveTab)
    const buildingSettingOptions = useAppSelector(selectBuildingSettingOptions)

    // get the field value, can be boolean from switch or string from dropdown
    const isSwitchActive = activeBuilding?.settings?.[buildingActiveTab!]?.[fieldKey]

    /**
     * when switch is string, that means that we display TWL field based on parent field
     * selected option
     *
     * string example
     * key: soffit_type, value: fiber cement, switchOption: soffit_type_fiber_cement
     * key: soffit_type, value: pvc, switchOption: soffit_type_pvc
     *
     * boolean example
     * key: soffit_type, value: do not have, switchOption: soffit_type
     */
    const switchOption = isString(isSwitchActive) ? `${fieldKey}_${convertValueToKey(isSwitchActive)}` : fieldKey

    /**
     * generate field key which is stored in building settings by specific tab
     *
     * string example
     * soffit_type_fiber_cement_dimensions_material - specific key since each option can have different TWL default data
     *
     * boolean example
     * soffit_type
     */
    const dimensionsFieldKey = `${switchOption}_${dimensionsMaterialKey}`

    // if it's undefined, set the value from default value into building settings
    if (showSwitch && isUndefined(isSwitchActive)) {
        dispatch(setBuildingsSelectedOptions({ key: fieldKey, value: defaultChecked }))
    }

    // when switch is false, not undefined or string, then we reset dimensions_material
    if (isSwitchActive === false) {
        dispatch(setBuildingsSelectedOptions({ key: dimensionsFieldKey, value: null }))
    }

    // handle reset selections when TWL is display based on parent field dropdown option
    if (isString(isSwitchActive) && buildingActiveTab && activeBuilding?.settings?.[buildingActiveTab]) {
        // get all tab settings
        const currentTabSettings = activeBuilding?.settings?.[buildingActiveTab]

        // get dropdown select value
        const selectedDropdownValue = convertValueToKey(currentTabSettings?.[fieldKey])

        // get all settings key that do not match selected value
        const settingsKeysToReset = Object.keys(currentTabSettings).filter(
            (key) =>
                key.startsWith(`${fieldKey}_`) &&
                key.endsWith(`_${dimensionsMaterialKey}`) &&
                key !== `${fieldKey}_${selectedDropdownValue}_${dimensionsMaterialKey}`
        )

        // reset selections
        settingsKeysToReset.forEach((key) => {
            dispatch(setBuildingsSelectedOptions({ key, value: null }))
        })
    }

    // set default data into TWL field if TWL field data is undefined or null
    if (
        isUndefined(activeBuilding?.settings?.[buildingActiveTab!]?.[dimensionsFieldKey]) ||
        activeBuilding?.settings?.[buildingActiveTab!]?.[dimensionsFieldKey] === null
    ) {
        // handle switch case and when switch is active
        if (isSwitchActive && isBoolean(isSwitchActive)) {
            dispatch(setBuildingsSelectedOptions({ key: dimensionsFieldKey, value: defaultDimensionValues[fieldKey] }))
        }

        // handle string cases
        if (isString(isSwitchActive)) {
            dispatch(setBuildingsSelectedOptions({ key: dimensionsFieldKey, value: defaultTWLData as object }))
        }
    }

    const onFieldChange = (value: string, fieldName: string) => {
        const data = {
            ...defaultDimensionValues[fieldKey],
            ...activeBuilding?.settings?.[buildingActiveTab!]?.[dimensionsFieldKey],
            [fieldName]: value,
        }

        dispatch(setBuildingsSelectedOptions({ key: dimensionsFieldKey, value: data }))
    }

    const enumSelectionValues = useMemo(() => {
        const fieldLumberOptions = getFieldLumberOptionKeys[fieldKey] || []

        const [lumberThickness = [], lumberWidth = [], lumberLength = []] = fieldLumberOptions.map(
            (fieldLumberOption) => {
                return buildingSettingOptions[buildingActiveTab!]?.get(fieldLumberOption) || []
            }
        )

        return {
            thickness: {
                enumNames: lumberThickness.map((data) => data?.label),
                enum: lumberThickness.map((data) => data?.value),
            },
            width: {
                enumNames: lumberWidth.map((data) => data?.label),
                enum: lumberWidth.map((data) => data?.value),
            },
            length: {
                enumNames: lumberLength.map((data) => data?.label),
                enum: lumberLength.map((data) => data?.value),
            },
        }
    }, [buildingSettingOptions, buildingActiveTab, fieldKey])

    return (
        <>
            {showSwitch && isBoolean(isSwitchActive) && (
                <Form.Group className="main-panel-item">
                    <Form.Label className="label">{label}</Form.Label>
                    <Form.Check
                        checked={isSwitchActive}
                        data-qa={`${prepareDataQA(label)}-input`}
                        defaultChecked={defaultChecked}
                        id={fieldKey}
                        inline
                        key={fieldKey}
                        onChange={(e): void => onChange(fieldKey, e.target.checked)}
                        type="switch"
                    />
                </Form.Group>
            )}

            {isSwitchActive && (
                <Form.Group className="main-panel-item">
                    <Form.Label className="label">Dimensions</Form.Label>
                    <LumberDimensionsSelectDropDown
                        fieldsData={
                            activeBuilding?.settings?.[buildingActiveTab!]?.[dimensionsFieldKey] ||
                            defaultDimensionValues
                        }
                        isLengthFieldDisabled={isLengthFieldDisabled}
                        onFieldChange={onFieldChange}
                        selectionValues={enumSelectionValues}
                        shouldIncludeLength={includeLength}
                        shouldIncludeThickness={includeThickness}
                        shouldIncludeWidth={includeWidth}
                    />
                </Form.Group>
            )}
        </>
    )
}

export default SwitchLumberDimensions
