import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, fork, put, select } from 'redux-saga/effects'

import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import { handleToggleActiveDrawable2D } from './handleToggleActiveDrawable'
import { selectAzureFeatureFlags } from '../../../imup/slices/flags'
import { OpeningGroup } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { AzureFeatureFlagIds } from '../../../shared/constants/azure-feature-flags'
import { NEW_IMUP_JOIST_TYPES_GROUP } from '../../../shared/constants/drawable-types'
import { JOIST_DIRECTION } from '../../../shared/constants/joist-directions'
import { isAzureFeatureFlagEnabled } from '../../../shared/services/azure-feature-flag-services/azure-feature-flag-services'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { setJoistDirection } from '../../slices/2D'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { activateTool, selectSnappingEnabled } from '../../slices/tools'

export function* handleSetActiveDrawableGroup2D({
    payload,
}: PayloadAction<{
    activeDrawableGroup: OpeningGroup
    activeDrawableId: number | undefined
}>) {
    try {
        const azureFeatureFlags: AzureFeatureFlag[] | null = yield select(selectAzureFeatureFlags)

        const isAISuggestionsFilterFlagEnabled: boolean = yield call(
            isAzureFeatureFlagEnabled,
            azureFeatureFlags,
            AzureFeatureFlagIds.ai_suggestions_filter
        )

        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const { id: activeDrawableGroupId, type } = payload.activeDrawableGroup

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const isSnappingEnabled = yield select(selectSnappingEnabled)

        const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)

        // Show only this drawable group when we activate it
        // If there is no active drawable group, we reset all shapes back to normal
        if (!activeDrawableGroupId) {
            const selectTool: Select = yield call(manager.getTool, Select.NAME)

            yield call(selectTool.removeHandles)
            yield call(workspaceTool.showAllDrawablesOnPage, activeFloor?.document_chunk.id ?? -1)
            if (payload.activeDrawableId) yield fork(handleToggleActiveDrawable2D, payload.activeDrawableId)

            // We still need to hide groups that were set to hidden
            const { hiddenDrawableGroupIds }: { hiddenDrawableGroupIds: number[]; visibleDrawableGroupIds: number[] } =
                yield call(getHiddenAndVisibleGroups)

            const visibilityTest = (item: paper.Item): boolean =>
                !hiddenDrawableGroupIds.includes(item.data.opening_group_id) && !isNonAreaJoistLine(item)

            const allPaperDrawablesOnPage: paper.Item[] = yield call(
                workspaceTool.getItemsWithCriteria,
                'data',
                function (data: any) {
                    return data?.drawable_id || data?.aiSuggestion?.id
                }
            )

            yield all(
                allPaperDrawablesOnPage.map((item) =>
                    call(
                        (item: paper.Item, visTest: (item: paper.Item) => boolean) => {
                            item.visible = visTest(item)
                        },
                        item,
                        visibilityTest
                    )
                )
            )

            // Select should be the active tool if we hit the cancel button
            yield put({ type: activateTool.type, payload: Select.NAME })
        } else {
            const visibilityTest = (item: paper.Item): boolean => {
                // keep all elements visible on page when snapping is active and items are visible
                if (isSnappingEnabled && item.visible) return true

                if (!isAISuggestionsFilterFlagEnabled) {
                    const isAISuggestion = item.data?.aiSuggestion?.id !== undefined

                    if (isAISuggestion) {
                        // Keep all suggestions of the same type and drawables with the same type
                        return type === item.data.aiSuggestion.type
                    }
                }

                // Keep all with the same opening group id or AI suggestion type
                return (
                    activeDrawableGroupId === item.data.opening_group_id &&
                    item.data.document_chunk_id === activeFloor?.document_chunk.id
                )
            }

            yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)
            if (payload.activeDrawableId) yield fork(handleToggleActiveDrawable2D, payload.activeDrawableId)

            if (NEW_IMUP_JOIST_TYPES_GROUP.includes(payload.activeDrawableGroup.type)) {
                yield put(
                    setJoistDirection(
                        payload.activeDrawableGroup.settings.direction
                            ? (payload.activeDrawableGroup.settings.direction.toLowerCase() as JOIST_DIRECTION)
                            : null
                    )
                )
            }

            for (const drawable of payload.activeDrawableGroup.openings) {
                const item = yield call(workspaceTool.getItemWithDrawableId, drawable.id)

                if (item) {
                    const colorAlpha = drawable.isActive ? item.data.activeOpacityValue : item.data.inactiveOpacityValue

                    if (item.fillColor) item.fillColor.alpha = colorAlpha
                    if (item.strokeColor) item.strokeColor.alpha = colorAlpha
                }
            }
        }
    } catch (error) {
        yield call(console.error, error)
    }
}
