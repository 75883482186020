import { createAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import { selectDrawableActiveFloor } from '../../../imup/slices/documents'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIMaterial, AIMaterialByFloor, SortedSuggestion } from '../../../models/aiClassifications'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { selectHiddenAIMaterialGroups, setHiddenAIMaterialGroups } from '../../slices/2D'

export const toggleAIMaterialVisibilityAction = createAction<{
    visibility: boolean
    group?: AIMaterial
    material?: SortedSuggestion
}>('toggleAIMaterialVisibilityAction')
export function* toggleAIMaterialVisibility(aiMaterialGroupPayload: {
    type: string
    payload: { visibility: boolean; group?: AIMaterial; material?: SortedSuggestion }
}) {
    const { group, material, visibility } = aiMaterialGroupPayload.payload

    // Return early if neither group nor material is provided, or if both are provided.
    // This saga expects exactly one of group or material to be present in the payload.
    if ((!group && !material) || (group && material)) return

    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

    const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)

    if (group) {
        const aiGroupByFloor: AIMaterialByFloor = {
            ...group,
            floorID: activeFloor?.id,
        }

        yield put(setHiddenAIMaterialGroups({ group: aiGroupByFloor, hidden: visibility }))
    }

    if (material) {
        for (const group of material.settingsGroups) {
            const aiGroupByFloor: AIMaterialByFloor = {
                ...group,
                floorID: group.aiMaterials[0].document_mapping_id,
            }

            yield put(setHiddenAIMaterialGroups({ group: aiGroupByFloor, hidden: visibility }))
        }
    }

    const hiddenAIGroups: AIMaterialByFloor[] = yield select(selectHiddenAIMaterialGroups)

    const aiIds: string[] = hiddenAIGroups.flatMap((group) => group.aiMaterials.map((material) => material.id))

    const aiItems: paper.Path[] = yield call(workspaceTool.getItemsWithCriteria, 'data', function (data: any) {
        return aiIds.includes(data?.aiSuggestion?.id)
    })

    const { hiddenDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)

    const visibilityTest = (item: paper.Item): boolean => {
        return (
            !aiItems.map((item) => item.id).includes(item.id) &&
            !(hiddenDrawableGroupIds.includes(item.data.opening_group_id) || isNonAreaJoistLine(item))
        )
    }

    yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)
}
export function* watchForToggleAIMaterialVisibility() {
    yield takeEvery(toggleAIMaterialVisibilityAction.type, toggleAIMaterialVisibility)
}
